var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import request from "apiClient";
function list(orgId, filtered, limit, page, sorted) {
  return request({
    url: `/api/${orgId}/unit_groups`,
    method: "GET",
    params: {
      filtered,
      limit,
      page,
      sorted
    }
  }).then((response) => {
    return {
      rows: response.unit_groups,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function get(orgId, unitGroupId) {
  return request({
    url: `/api/${orgId}/unit_groups/${unitGroupId}`,
    method: "GET"
  });
}
function units(orgId, unitGroupId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/unit_groups/${unitGroupId}/units`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function removeUnit(orgId, unitGroupId, unitId, unitType) {
  return request({
    url: `/api/${orgId}/unit_groups/${unitGroupId}/remove_unit`,
    method: "PATCH",
    data: {
      unit_id: unitId,
      unit: unitType
    }
  });
}
function unlinked(orgId, unitGroupId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/unit_groups/${unitGroupId}/unlinked`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function addUnit(orgId, unitGroupId, unitId, unitType) {
  return request({
    url: `/api/${orgId}/unit_groups/${unitGroupId}/add_unit`,
    method: "POST",
    data: {
      unit_id: unitId,
      unit: unitType
    }
  });
}
function update(organizationId, unitGroupId, rateGroupRangeId, data) {
  return request({
    url: `/api/${organizationId}/unit_groups/${unitGroupId}`,
    method: "PATCH",
    data: __spreadProps(__spreadValues({}, data), {
      rate_group_range_id: rateGroupRangeId
    })
  });
}
function removeFromRange(organizationId, unitGroupId, rateGroupRangeId) {
  return request({
    url: `/api/${organizationId}/unit_groups/${unitGroupId}/remove_from_range`,
    method: "PATCH",
    data: {
      unit_group_id: unitGroupId,
      rate_group_range_id: rateGroupRangeId
    }
  });
}
const UnitGroupService = {
  list,
  get,
  units,
  removeUnit,
  unlinked,
  addUnit,
  update,
  removeFromRange
};
export default UnitGroupService;
