var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  invoices: {
    refreshInvoices: false
  },
  chartOfAccounts: {
    openLedgerModal: false,
    openJournalModal: false
  },
  earning: {}
};
const accountingSlice = createSlice({
  name: "accounting",
  initialState,
  reducers: {
    updateAccountingInvoices: (state, action) => {
      return __spreadProps(__spreadValues({}, state), { invoices: __spreadValues(__spreadValues({}, state.invoices), action.payload) });
    },
    resetAccountingInvoices: (state) => {
      return __spreadProps(__spreadValues({}, state), { invoices: initialState.invoices });
    },
    updateAccountingChartOfAccounts: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        chartOfAccounts: __spreadValues(__spreadValues({}, state.chartOfAccounts), action.payload)
      });
    },
    resetAccountingChartOfAccounts: (state) => {
      return __spreadProps(__spreadValues({}, state), { chartOfAccounts: initialState.chartOfAccounts });
    },
    loadEarningAcccount: (state, action) => {
      return __spreadProps(__spreadValues({}, state), { earning: __spreadValues({}, action.payload) });
    },
    resetEarningAccount: (state) => {
      return __spreadProps(__spreadValues({}, state), { earning: initialState.earning });
    }
  }
});
export const {
  updateAccountingInvoices,
  resetAccountingInvoices,
  updateAccountingChartOfAccounts,
  resetAccountingChartOfAccounts,
  loadEarningAcccount,
  resetEarningAccount
} = accountingSlice.actions;
export default accountingSlice.reducer;
export const selectAccountingInvoicesState = createSelector((state) => state.accounting, (accounting) => accounting.invoices);
export const selectAccountingChartOfAccounts = createSelector((state) => state.accounting, (accounting) => accounting.chartOfAccounts);
export const selectEarningAccount = createSelector((state) => state.accounting, (accounting) => accounting.earning);
