var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  name: "",
  email: "",
  role: "",
  team_id: null,
  telephone: "",
  adr_street: "",
  adr_unit: "",
  adr_city: "",
  adr_state: "",
  adr_country: "",
  adr_postal_code: "",
  entity: "",
  user_group: "",
  payout_frequency: "monthly",
  portfolio: "",
  margin: 0,
  needs_confirmation: false,
  business_name: "",
  teams: [],
  showOverlay: false,
  calculation_type: "percent"
};
const createEmployeeSlice = createSlice({
  name: "newEmployee",
  initialState,
  reducers: {
    updateNewEmployee: (state, action) => {
      return __spreadValues(__spreadValues({}, state), action.payload);
    },
    resetNewEmployee: (state) => {
      return __spreadValues(__spreadValues({}, state), initialState);
    }
  }
});
export const {
  updateNewEmployee,
  resetNewEmployee
} = createEmployeeSlice.actions;
export default createEmployeeSlice.reducer;
export const selectNewEmployee = createSelector((state) => state.newEmployee, (employee) => employee);
