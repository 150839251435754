var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  id: null,
  first_name: "",
  last_name: "",
  permissions: {},
  role: null,
  role_name: null,
  type: null,
  organization: {},
  rvshare: {
    external_id: null,
    mappable_id: null,
    id: null,
    hasAttemptedSync: false,
    active: false,
    syncMonitor: "rest"
  }
};
const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    updateUserDetails: (state, action) => {
      return __spreadValues(__spreadValues({}, state), action.payload);
    },
    resetUserDetails: (state) => {
      return __spreadValues(__spreadValues({}, state), initialState);
    }
  }
});
export const { updateUserDetails, resetUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
export const selectUserDetails = createSelector((state) => state.userDetails, (userDetails) => userDetails);
export const selectUserOrganization = createSelector((state) => state.userDetails.organization, (organization) => organization);
