import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loading from 'react-loading-components';
import ReactPaginate from 'react-paginate';
import CurrencyDefinitions from 'sharedUtils/currency-definitions';
import {
  Table,
  TextDense,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import { nanoid } from 'nanoid';
import NoResults from './no-results';
import { useDetectMobile } from '../../../shared/hooks';
import { formatCurrency } from '../../../shared/utils';

const DirectUiKitTable = props => {
  const { isMobile } = useDetectMobile();
  const {
    data,
    pages,
    loading,
    onFetchData,
    organization,
    columns,
    defaultPageSize,
    showNoResultsImage,
    paginateOnly,
    denseOnMobile,
    emptyLoadingState,
    renderMobileView,
    mobileViewVisibility
  } = props;
  const [tableState, setTableState] = useState({
    page: 0
  });
  const [initialMount, setInitialMount] = useState(true);

  const compactObject = obj => {
    const newObj = {};
    if (obj) {
      Object.keys(obj).map(key => {
        if (
          Object.prototype.hasOwnProperty.call(obj, key) &&
          obj[key] !== undefined &&
          typeof obj[key] !== 'undefined'
        ) {
          newObj[key] = obj[key];
        }
        return true;
      });
    }
    return newObj;
  };

  const getResolvedState = () => {
    const resolvedState = {
      ...compactObject(tableState),
      ...compactObject(props)
    };
    return resolvedState;
  };

  const renderCell = (instance, column) => {
    if (typeof column?.Cell === 'function') {
      return column.Cell(instance);
    }

    if (typeof column?.accessor === 'string') {
      let columnValue = instance[column.accessor];
      if (column.accessor === 'calculation_amount') {
        const currencyDetails = CurrencyDefinitions.filter(
          definition => definition.value === organization.currency
        )[0];
        columnValue = formatCurrency(columnValue, currencyDetails.value);
        columnValue =
          instance.usage_type === 'Mileage'
            ? `${columnValue} per mile`
            : `${columnValue} per hour`;
      }
      if (column.accessor === 'amount_free') {
        columnValue =
          instance.usage_type === 'Mileage'
            ? `${columnValue || 0} miles`
            : `${columnValue || 0} hours`;
      }
      return (
        <Table.Cell
          key={`${instance.id}-${column.accessor}`}
          {...column.cellProps}
        >
          {column.emphasizeText ? (
            <TextDense
              weight="semibold"
              className={classnames({
                globalSearch__noWrap_mobile: column.noWrapMobile
              })}
            >
              {columnValue}
            </TextDense>
          ) : (
            <div
              className={classnames({
                globalSearch__noWrap_mobile: column.noWrapMobile
              })}
            >
              {columnValue}
            </div>
          )}
        </Table.Cell>
      );
    }

    return !column ? null : (
      <Table.Cell
        key={`${instance.id}-col-${column.name}`}
        {...column.cellProps}
      >
        {column.accessor(instance)}
      </Table.Cell>
    );
  };

  const renderHeaderCell = column => {
    if (typeof column?.Header === 'function') {
      return column.Header();
    }

    return !column ? null : (
      <Table.Header
        key={`col-header-${nanoid(7)}-${column.Header}`}
        {...column.headerProps}
      >
        {column.noWrapMobile ? (
          <div className="globalSearch__noWrap_mobile">{column.Header}</div>
        ) : (
          column.Header
        )}
      </Table.Header>
    );
  };

  const handlePageClick = data => {
    setTableState({
      page: data.selected
    });
  };

  const renderRow = instance => {
    if (instance.customRow) return instance.row();
    if (typeof instance === 'function') {
      return instance();
    }

    return (
      <tr key={instance.id}>
        {columns.map(column => {
          return renderCell(instance, column);
        })}
      </tr>
    );
  };

  const renderBody = tableData => {
    if (!tableData) return null;
    let bodyData = tableData;

    if (paginateOnly) {
      const sliceStart = tableState.page * defaultPageSize;
      const sliceEnd = (tableState.page + 1) * defaultPageSize;

      bodyData = [...bodyData].slice(sliceStart, sliceEnd);
    }

    return bodyData.map(instance => renderRow(instance));
  };

  const renderTable = () => {
    return (
      <Table
        isNarrow={denseOnMobile && isMobile}
        isScrollable={!props.removeScrollContainer}
      >
        <thead>
          <tr>{columns.map(column => renderHeaderCell(column))}</tr>
        </thead>
        <tbody>{renderBody(data)}</tbody>
      </Table>
    );
  };

  useEffect(
    () => {
      if (!paginateOnly && !initialMount) {
        onFetchData(getResolvedState());
      }
    },
    [tableState]
  );

  useEffect(() => {
    if (!props.disableFetchOnMount) {
      onFetchData(getResolvedState());
    }
    setInitialMount(false);
  }, []);

  return loading ? (
    <>
      {emptyLoadingState ? (
        <></>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            width: '100%'
          }}
        >
          <Loading type="tail_spin" width={50} height={50} fill="#00CC99" />
        </div>
      )}
    </>
  ) : (
    <div>
      {data?.length > 0 && (
        <>
          {renderMobileView ? (
            <>
              <VisibilityHelper hideStartingFrom={mobileViewVisibility}>
                {renderMobileView(data)}
              </VisibilityHelper>
              <VisibilityHelper showStartingFrom={mobileViewVisibility}>
                {renderTable()}
              </VisibilityHelper>
            </>
          ) : (
            renderTable()
          )}
        </>
      )}
      {data?.length < 1 && showNoResultsImage && (
        <NoResults
          headline={props.noResultsHeadline}
          description={props.noResultsDescription}
        />
      )}
      {pages > 1 && (
        <div className="consolidated-key">
          <div
            style={{ float: 'right', paddingTop: '10px' }}
            className="no-wrap"
          >
            <ReactPaginate
              previousLabel="previous"
              nextLabel="next"
              breakLabel={<a href="">...</a>}
              breakClassName="break-me"
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={4}
              onPageChange={handlePageClick}
              forcePage={tableState.page}
              containerClassName=""
              activeClassName="active"
            />
          </div>
        </div>
      )}
    </div>
  );
};

DirectUiKitTable.propTypes = {
  data: PropTypes.array,
  pages: PropTypes.number,
  loading: PropTypes.bool,
  onFetchData: PropTypes.func,
  organization: PropTypes.object,
  columns: PropTypes.array,
  defaultPageSize: PropTypes.number,
  showNoResultsImage: PropTypes.bool,
  paginateOnly: PropTypes.bool,
  denseOnMobile: PropTypes.bool,
  emptyLoadingState: PropTypes.bool,
  renderMobileView: PropTypes.func,
  mobileViewVisibility: PropTypes.oneOf(['tablet', 'large-tablet', 'desktop'])
};

DirectUiKitTable.defaultProps = {
  onFetchData: () => {},
  defaultPageSize: 50,
  denseOnMobile: false,
  mobileViewVisibility: 'large-tablet'
};

DirectUiKitTable.Cell = Table.Cell;
DirectUiKitTable.Header = Table.Header;

export default DirectUiKitTable;
