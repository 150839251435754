import { hot } from 'react-hot-loader/root';
import React from 'react';
import 'react-dates/initialize'; // Needed for rendering any react-dates components
import { Provider } from 'react-redux';
import loadable from '@loadable/component';

import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import HoneybadgerErrorBoundary from 'adminComponents/Honeybadger/Honeybadger';

import Notification from 'sharedComponents/Notification';

/* Navigation */
import { ApolloProvider } from '@apollo/react-hooks';
import CustomerSidebar from 'adminComponents/CustomerSidebar';
import CustomerProvider from 'adminProviders/CustomerProvider';
import { OrganizationProvider } from 'adminProviders/OrganizationProvider';

/* Pages */
/* Eventually, we will want to break these down into Container components for each section of the application and use this as simply a jumping off point */

/* Dashboard */
import VehicleProvider from 'adminProviders/VehicleProvider';
const OrganizationDashboard = loadable(() => import('./pages/DashboardOrganization/organisms/organization-dashboard'));

/* Channels */
const ListChannels = loadable(() => import('./pages/DashboardChannel/organisms/list-channels'));

/* Portfolios */
const ListPortfolios = loadable(() => import('./pages/DashboardPortfolio/organisms/list-portfolios'));
const ManagePortfolio = loadable(() => import('./pages/DashboardPortfolio/organisms/manage-portfolio'));

/* Subportfolios */
const SubportfoliosList = loadable(() => import('./pages/DashboardSubportfolio/SubportfoliosList'));
const ManageSubportfolio = loadable(() => import('./pages/DashboardSubportfolio/ManageSubportfolio'));

/* Unit Groups */
const UnitGroupsList = loadable(() => import('./pages/DashboardUnitGroups/UnitGroupsList'));
const ManageUnitGroup = loadable(() => import('./pages/DashboardUnitGroups/ManageUnitGroup'));

/* Rate Groups */
const RateGroupsList = loadable(() => import('./pages/DashboardRateGroup/RateGroupsList'));
const ManageRateGroup = loadable(() => import('./pages/DashboardRateGroup/ManageRateGroup'));

/* Rate Group Ranges */
const ManageRateGroupRange = loadable(() => import('./pages/DashboardRateGroup/RateGroupRanges/ManageRateGroupRange'));

/* Promotions */
const PromotionsList = loadable(() => import('./pages/DashboardPromotions/PromotionsList'));
const ManagePromotion = loadable(() => import('./pages/DashboardPromotions/ManagePromotion'));

/* Rooms */
const ListRoomTypes = loadable(() => import('./pages/DashboardRoomTypes/ListRoomTypes'));
const ManageRoomTypes = loadable(() => import('./pages/DashboardRoomTypes/ManageRoomType'));

/* Properties */
const ListProperties = loadable(() => import('./pages/DashboardProperty/organisms/list-properties'));
const PropertiesAudit = loadable(() => import('./pages/DashboardProperty/organisms/properties-audit'));
const NewProperty = loadable(() => import('./pages/DashboardProperty/organisms/new-property'));
const FeaturesProperty = loadable(() => import('./pages/DashboardProperty/organisms/features-property'));
const PropertyHistory = loadable(() => import('./pages/DashboardProperty/organisms/property-history'));
const PropertyDetailsRouter = loadable(() => import('./pages/DashboardProperty/organisms/property-details-router'));

/* Units */
const UnitDetailsRouter = loadable(() => import('./pages/DashboardUnit/organisms/unit-details-router'));

/* Reservations */
const ListReservations = loadable(() => import('./pages/DashboardReservation/organisms/list-reservations'));

/* Employees */
const ListEmployees = loadable(() => import('./pages/DashboardEmployee/organisms/list-employees'));
const NewEmployee = loadable(() => import('./pages/DashboardEmployee/organisms/new-employee'));
const ManageAccount = loadable(() => import('./pages/DashboardAccount/manage-account'));

/* Teams (aka User Groups) */
const ListTeams = loadable(() => import('./teams/list-teams'));
const NewTeam = loadable(() => import('./teams/new-team'));

/* Customers */
const ListCustomers = loadable(() => import('./customers/list-customers'));

/* Leads */
const ListLeads = loadable(() => import('./customers/list-leads'));

const ManageEvents = loadable(() => import('./pages/DashboardEvent/organisms/manage-events'));

/* Brands */
const ListBrands = loadable(() => import('./pages/DashboardBrand/organisms/list-brands'));
const NotificationsApp = loadable(() => import('./pages/NotificationsApp'));

/* Trust Accounts */
const ListTrustAccounts = loadable(() => import('./trust_accounts/list-trust-accounts'));
const ManageTrustAccount = loadable(() => import('./trust_accounts/manage-trust-account'));

/* Accounting */
const ListBalances = loadable(() => import('./statements/list-balances'));
const ManageBalance = loadable(() => import('./statements/manage-balance'));
const ManageStatement = loadable(() => import('./statements/manage-statement'));
const ListStatements = loadable(() => import('./statements/list-statements'));
const StatementSettings = loadable(() => import('./statements/statement-settings'));
const BulkSettingsApp = loadable(() => import('./pages/BulkSettingsApp'));
const BulkSettingsHistory = loadable(() => import('./pages/BulkSettingsApp/BulkSettingsHistory'));
const ChartOfAccountsApp = loadable(() => import('./pages/ChartOfAccountsApp'));
const Ledgers = loadable(() => import('./pages/Ledgers'));
const StatementChangeLog = loadable(() => import('./statements/statement-change-log'));
const Reconcile = loadable(() => import('./pages/Reconcile'));

/* Payments */
const ListPaymentsDetails = loadable(() => import('./pages/DashboardPayout/organisms/list-payments-details'));
const ListPayments = loadable(() => import('./pages/DashboardPayout/organisms/list-payments'));

const PayeeListStatements = loadable(() => import('./statements/payee_views/payee-list-statements'));

/* Conversations */

/* Subscriptions */
const ManageSubscription = loadable(() => import('./subscriptions/manage-subscription'));

/* Work Orders & Workflows */
const ListWorkflows = loadable(() => import('./workflows/list-workflows'));
const ManageWorkflow = loadable(() => import('./workflows/manage-workflow'));
const ListWorkOrders = loadable(() => import('./work_orders/list-work-orders'));
const NewWorkOrder = loadable(() => import('./work_orders/new-work-order'));

const ListWorkReports = loadable(() => import('./work_reports/list-work-reports'));

const PointCentralMappingsList = loadable(() => import('./Integrations/PointCentral/PointCentralMappingsList'));
const KabaMappingsList = loadable(() => import('./Integrations/Kaba/KabaMappingsList'));
const IntegrationsList = loadable(() => import('./Integrations/IntegrationsList'));

/* Csv Import */
const CsvImportApp = loadable(() => import('./csv_imports/csv-import-app'));


/* Invoices */
const ListBills = loadable(() => import('./bills/pages/list-bills'));
const ShowBill = loadable(() => import('./bills/pages/show-bill'));

const OpportunityPipeline = loadable(() => import('./pages/OpportunityPipeline'));
const Scheduling = loadable(() => import('./workflows-scheduling/scheduling'));
const BdcLogs = loadable(() => import('./pages/DashboardProperty/organisms/bdc-logs'));
const ListVehicles = loadable(() => import('./pages/vehicles/organisms/ListVehicles'));
const UnifiedInboxWrapper = loadable(() => import('./pages/DashboardUnifiedInbox/organisms/unified-inbox-wrapper'));
const BulkEditVehicles = loadable(() => import('./pages/BulkEditApp/organisms/vehicles/bulk-edit-vehicles'));
const VehicleDetailsRouter = loadable(() => import('./pages/vehicles/organisms/VehicleDetailsRouter'));
const ReservationsDetailsRouter = loadable(() => import('./pages/DashboardReservation/organisms/reservations-details-router'));
const WorkOrderRouter = loadable(() => import('./work_orders/work-order-router'));
const WorkReportRouter = loadable(() => import('./work_reports/work-report-router'));
const ManageEmployeeRouter = loadable(() => import('./pages/DashboardEmployee/organisms/manage-employee-router'));
const UserGroupsRouter = loadable(() => import('./teams/user-groups-router'));
const CmsSwitcher = loadable(() => import('./pages/DashboardBrand/organisms/cms-switcher'));

const SearchResults = loadable(() => import('./pages/DashboardGlobalSearch/SearchResults'));
const DetailedSearchBooking = loadable(() => import('./pages/DashboardGlobalSearch/components/DetailedSearchBooking'));
const DetailedSearchProperty = loadable(() => import('./pages/DashboardGlobalSearch/components/DetailedSearchProperty'));
const DetailedSearchCustomer = loadable(() => import('./pages/DashboardGlobalSearch/components/DetailedSearchCustomer'));
const DetailedSearchEmployee = loadable(() => import('./pages/DashboardGlobalSearch/components/DetailedSearchEmployee'));
const DetailedSearchStatement = loadable(() => import('./pages/DashboardGlobalSearch/components/DetailedSearchStatement'));
const DetailedSearchVehicle = loadable(() => import('./pages/DashboardGlobalSearch/components/DetailedSearchVehicle'));
const NewUnit = loadable(() => import('./pages/DashboardUnit/organisms/new-unit'));
const ChannelRouter = loadable(() => import('./pages/DashboardChannel/organisms/channel-router'));

const ReportSingle = loadable(() => import('./pages/DashboardReport/organisms'), {
  resolveComponent: components => components.ReportSingle
});
const ReportIndex = loadable(() => import('./pages/DashboardReport/organisms'), {
  resolveComponent: components => components.ReportIndex
});
const ManageOrganization = loadable(() => import('./pages/DashboardOrganization/organisms'), {
  resolveComponent: components => components.ManageOrganization
});
const ListReviews = loadable(() => import('./pages/DashboardReviews/organisms'), {
  resolveComponent: components => components.ListReviews
});
const AccountMappingApp = loadable(() => import('./account_mapping/account_mapping_app'), {
  resolveComponent: components => components.AccountMappingApp
});
const BulkEditProperties = loadable(() => import('./pages/BulkEditApp/organisms'), {
  resolveComponent: components => components.BulkEditProperties
});
const CreateBrand = loadable(() => import('./pages/DashboardBrand/organisms/create-brand'));
const ManageWheelhouseListings = loadable(() => import('./pages/ManageWheelhouseListings'), {
  resolveComponent: components => components.ManageWheelhouseListings
});

import ConfigProvider from './providers/ConfigProvider';
import InitialReduxState from './utilities/InitialReduxState';
import NavContainer from './navigation/design-system-nav/nav-container';
import ScrollToTop from './utilities/ScrollToTop';
import client from './apollo-client';
import store from './redux/store.js';

class AdminApp extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      activeProperty: null,
      activeUnit: null,
      activeListing: null
    };
  }

  setActiveProperty = (property, unit, listing) => {
    this.setState({
      activeProperty: property,
      activeUnit: unit,
      activeListing: listing
    });
  };

  render() {
    const { activeProperty, activeUnit, activeListing } = this.state;
    return (
      <HoneybadgerErrorBoundary>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <OrganizationProvider
              organization={{
                ...this.props.organization,
                onboarded: this.props.onboarded,
                subscription: this.props.subscription
              }}
            >
              <CustomerProvider
                organization={this.props.organization}
                organizationID={this.props.organization.id}
                user={this.props.user}
              >
                <ConfigProvider>
                  <VehicleProvider organization={this.props.organization}>
                    <Router
                      basename={`/dashboard/${
                        this.props.organization.subdomain
                      }`}
                    >
                      <LastLocationProvider>
                        <ScrollToTop />
                        <InitialReduxState {...this.props} />
                        <NavContainer
                          {...this.props}
                          activeProperty={activeProperty}
                          activeUnit={activeUnit}
                          activeListing={activeListing}
                        />
                        <div className="mainApp">
                          <Notification />
                          <CustomerSidebar />
                          <main className="admin content">
                            <Switch>
                              <Route
                                path="/account-mapping"
                                render={routeProps => (
                                  <AccountMappingApp
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/csv-importer"
                                render={routeProps => (
                                  <CsvImportApp
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/portfolios/:portfolioId"
                                render={routeProps => (
                                  <ManagePortfolio
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/portfolios"
                                render={routeProps => (
                                  <ListPortfolios
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/sub-portfolios/:subportfolioId"
                                render={routeProps => (
                                  <ManageSubportfolio
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/sub-portfolios"
                                render={routeProps => (
                                  <SubportfoliosList
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/unit-groups/:unitGroupId"
                                render={routeProps => (
                                  <ManageUnitGroup
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/unit-groups"
                                render={routeProps => (
                                  <UnitGroupsList
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/rate-groups/:rateGroupId"
                                render={routeProps => (
                                  <ManageRateGroup
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/rate-groups"
                                render={routeProps => (
                                  <RateGroupsList
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/rate-group-ranges/:rateGroupRangeId"
                                render={routeProps => (
                                  <ManageRateGroupRange
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/promotions/:promotionId"
                                render={routeProps => (
                                  <ManagePromotion
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/promotions"
                                render={routeProps => (
                                  <PromotionsList
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/room-types/:roomTypeId"
                                render={routeProps => (
                                  <ManageRoomTypes
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/room-types"
                                render={routeProps => (
                                  <ListRoomTypes
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/properties/new"
                                render={routeProps => (
                                  <NewProperty
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/properties/:propertyId/features"
                                render={routeProps => (
                                  <FeaturesProperty
                                    {...this.props}
                                    {...routeProps}
                                    activeProperty={activeProperty}
                                    activeUnit={activeUnit}
                                    activeListing={activeListing}
                                  />
                                )}
                              />
                              <Route
                                path="/properties/:propertyId/history"
                                render={routeProps => (
                                  <PropertyHistory
                                    {...this.props}
                                    {...routeProps}
                                    setActiveProperty={this.setActiveProperty}
                                    activeProperty={activeProperty}
                                    activeUnit={activeUnit}
                                    activeListing={activeListing}
                                  />
                                )}
                              />
                              <Route
                                path="/properties/:propertyId/units/new"
                                render={routeProps => (
                                  <NewUnit
                                    {...this.props}
                                    {...routeProps}
                                    setActiveProperty={this.setActiveProperty}
                                    activeProperty={activeProperty}
                                    activeUnit={activeUnit}
                                    activeListing={activeListing}
                                  />
                                )}
                              />
                              <Route
                                path="/properties/:propertyId/units/:unitId"
                                render={routeProps => (
                                  <UnitDetailsRouter
                                    {...this.props}
                                    {...routeProps}
                                    setActiveProperty={this.setActiveProperty}
                                    activeProperty={activeProperty}
                                    activeUnit={activeUnit}
                                    activeListing={activeListing}
                                  />
                                )}
                              />
                              <Route
                                path="/audit-properties"
                                render={routeProps => (
                                  <PropertiesAudit
                                    {...this.props}
                                    {...routeProps}
                                    activeProperty={activeProperty}
                                    activeUnit={activeUnit}
                                    activeListing={activeListing}
                                  />
                                )}
                              />
                              <Route
                                path="/properties/:propertyId"
                                render={routeProps => (
                                  <PropertyDetailsRouter
                                    setActiveProperty={this.setActiveProperty}
                                    {...this.props}
                                    {...routeProps}
                                    activeProperty={activeProperty}
                                    activeUnit={activeUnit}
                                    activeListing={activeListing}
                                  />
                                )}
                              />
                              <Route
                                path="/vehicles/:vehicleId"
                                render={routeProps => (
                                  <VehicleDetailsRouter
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/vehicles"
                                render={routeProps => (
                                  <ListVehicles
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/properties"
                                render={routeProps => (
                                  <ListProperties
                                    {...this.props}
                                    {...routeProps}
                                    activeProperty={activeProperty}
                                    activeUnit={activeUnit}
                                    activeListing={activeListing}
                                  />
                                )}
                              />
                              <Route
                                path="/workflows/scheduling"
                                render={routeProps => (
                                  <Scheduling {...this.props} {...routeProps} />
                                )}
                              />
                              <Route
                                path="/workflows/work-orders/completed"
                                render={routeProps => (
                                  <ListWorkOrders
                                    completed
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/workflows/work-orders/new"
                                render={routeProps => (
                                  <NewWorkOrder
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/workflows/work-orders/:workOrderId"
                                render={routeProps => (
                                  <WorkOrderRouter
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/workflows/work-orders"
                                render={routeProps => (
                                  <ListWorkOrders
                                    completed={false}
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/workflows/work-reports/:workReportId"
                                render={routeProps => (
                                  <WorkReportRouter
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/workflows/work-reports"
                                render={routeProps => (
                                  <ListWorkReports
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/workflows/:workflowId"
                                render={routeProps => (
                                  <ManageWorkflow
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/workflows"
                                render={routeProps => (
                                  <ListWorkflows
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/employees/new"
                                render={routeProps => (
                                  <NewEmployee
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/employees/:employeeId"
                                render={routeProps => (
                                  <ManageEmployeeRouter
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/employees"
                                render={routeProps => (
                                  <ListEmployees
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/groups/new"
                                render={routeProps => (
                                  <NewTeam {...this.props} {...routeProps} />
                                )}
                              />
                              <Route
                                path="/groups/:teamId"
                                render={routeProps => (
                                  <UserGroupsRouter
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/groups"
                                render={routeProps => (
                                  <ListTeams {...this.props} {...routeProps} />
                                )}
                              />
                              <Route
                                path="/customers"
                                render={routeProps => (
                                  <ListCustomers
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/leads"
                                render={routeProps => (
                                  <ListLeads {...this.props} {...routeProps} />
                                )}
                              />
                              <Route
                                path="/pipeline"
                                render={routeProps => (
                                  <OpportunityPipeline
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                exact
                                path="/channels/booking/bdc-logs"
                                render={routeProps => (
                                  <BdcLogs
                                    {...this.props}
                                    {...this.state}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/channels/:slug"
                                render={routeProps => (
                                  <ChannelRouter
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/channels"
                                render={routeProps => (
                                  <ListChannels
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/reservations/vehicles_calendar"
                                render={routeProps => (
                                  <ListReservations
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/reservations/calendar"
                                render={routeProps => (
                                  <ListReservations
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/reservations/list"
                                render={routeProps => (
                                  <ListReservations
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/reservations/:bookingCode"
                                render={routeProps => (
                                  <ReservationsDetailsRouter
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/reservations"
                                render={routeProps => (
                                  <ListReservations
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />

                              <Route
                                path="/reviews"
                                render={routeProps => (
                                  <ListReviews
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />

                              <Route
                                path="/events"
                                render={routeProps => (
                                  <ManageEvents
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/settings/billing"
                                render={routeProps => (
                                  <ManageSubscription
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/settings/integrations/point-central"
                                render={routeProps => (
                                  <PointCentralMappingsList
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/settings/integrations/kaba"
                                render={routeProps => (
                                  <KabaMappingsList
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/settings/integrations"
                                render={routeProps => (
                                  <IntegrationsList
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/wheelhouse/listings"
                                render={() => (
                                  <ManageWheelhouseListings />
                                )}
                              />
                              <Route
                                path="/settings"
                                render={routeProps => (
                                  <ManageOrganization
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/globalsearch"
                                render={routeProps => (
                                  <SearchResults
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/global_search/detailed_booking"
                                render={routeProps => (
                                  <DetailedSearchBooking
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/global_search/detailed_property"
                                render={routeProps => (
                                  <DetailedSearchProperty
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/global_search/detailed_customer"
                                render={routeProps => (
                                  <DetailedSearchCustomer
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/global_search/detailed_employee"
                                render={routeProps => (
                                  <DetailedSearchEmployee
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/global_search/detailed_statement"
                                render={routeProps => (
                                  <DetailedSearchStatement
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/global_search/detailed_vehicle"
                                render={routeProps => (
                                  <DetailedSearchVehicle
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              {/* Add additional tool sections */}
                              <Route
                                path="/payments/statements/:id"
                                render={routeProps => (
                                  <ManageStatement
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/payments/statements"
                                render={routeProps => (
                                  <PayeeListStatements
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/payments/invoices"
                                render={routeProps => (
                                  <ListBills {...this.props} {...routeProps} />
                                )}
                              />
                              <Route
                                path="/payments/:employeeId"
                                render={routeProps => (
                                  <ListPaymentsDetails
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/payments"
                                render={routeProps => (
                                  <ListPayments
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />

                              {/* <Route
                              path="/reporting"
                              render={routeProps => (
                                <ReportRouter {...this.props} {...routeProps} />
                              )}
                            /> */}
                              <Route
                                path="/reporting/:report_type"
                                render={routeProps => (
                                  <ReportSingle
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/reporting"
                                render={routeProps => (
                                  <ReportIndex
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting/earnings-summaries/:payeeId"
                                render={routeProps => (
                                  <ListPaymentsDetails
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting/earnings-summaries"
                                render={routeProps => (
                                  <ListPayments
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting/bookings-summary"
                                render={routeProps => (
                                  <Ledgers {...this.props} {...routeProps} />
                                )}
                              />
                              <Route
                                path="/accounting/reconcile"
                                render={routeProps => (
                                  <Reconcile {...this.props} {...routeProps} />
                                )}
                              />
                              <Route
                                path="/accounting/invoices/:id"
                                render={routeProps => (
                                  <ShowBill {...this.props} {...routeProps} />
                                )}
                              />
                              <Route
                                path="/accounting/invoices"
                                render={routeProps => (
                                  <ListBills {...this.props} {...routeProps} />
                                )}
                              />
                              <Route
                                path="/accounting/bank-accounts/:id"
                                render={routeProps => (
                                  <ManageTrustAccount
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting/bank-accounts"
                                render={routeProps => (
                                  <ListTrustAccounts
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting/balances/:payeeId"
                                render={routeProps => (
                                  <ManageBalance
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting/statements/settings"
                                render={routeProps => (
                                  <StatementSettings
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="accounting/bulk-settings/history"
                                render={routeProps => (
                                  <BulkSettingsHistory
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting/bulk-settings"
                                render={routeProps => (
                                  <BulkSettingsApp
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting/chart-of-accounts"
                                render={routeProps => (
                                  <ChartOfAccountsApp
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting/statements/:id/history"
                                render={routeProps => (
                                  <StatementChangeLog
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting/statements/:id"
                                render={routeProps => (
                                  <ManageStatement
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting/statements"
                                render={routeProps => (
                                  <ListStatements
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/accounting"
                                render={routeProps => (
                                  <ListBalances
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/account"
                                render={routeProps => (
                                  <ManageAccount
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/messaging"
                                render={routeProps => (
                                  <NotificationsApp
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/brands/new"
                                render={routeProps => (
                                  <CreateBrand
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/brands/:brandId"
                                render={routeProps => (
                                  <CmsSwitcher
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/brands"
                                render={routeProps => (
                                  <ListBrands {...this.props} {...routeProps} />
                                )}
                              />
                              <Route
                                exact
                                path="/inbox/settings"
                                render={routeProps => (
                                  <UnifiedInboxWrapper
                                    inboxView="settings"
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/inbox/:inboxLabel/conversation/:conversationId"
                                render={routeProps => (
                                  <UnifiedInboxWrapper
                                    inboxLabel={
                                      routeProps.match.params.inboxLabel
                                    }
                                    conversationId={
                                      routeProps.match.params.conversationId
                                    }
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/inbox/conversation/:conversationId"
                                render={routeProps => (
                                  <UnifiedInboxWrapper
                                    conversationId={
                                      routeProps.match.params.conversationId
                                    }
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/inbox/:inboxLabel"
                                render={routeProps => (
                                  <UnifiedInboxWrapper
                                    inboxLabel={
                                      routeProps.match.params.inboxLabel
                                    }
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/inbox"
                                render={routeProps => (
                                  <UnifiedInboxWrapper
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/bulk-edit/properties"
                                render={routeProps => (
                                  <BulkEditProperties
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/bulk-edit/vehicles"
                                render={routeProps => (
                                  <BulkEditVehicles
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                              <Route
                                path="/"
                                render={routeProps => (
                                  <OrganizationDashboard
                                    {...this.props}
                                    {...routeProps}
                                  />
                                )}
                              />
                            </Switch>
                          </main>
                        </div>
                      </LastLocationProvider>
                    </Router>
                  </VehicleProvider>
                </ConfigProvider>
              </CustomerProvider>
            </OrganizationProvider>
          </Provider>
        </ApolloProvider>
      </HoneybadgerErrorBoundary>
    );
  }
}

export default hot(AdminApp);
