var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  bulkEditorUi,
  bulkEditorActiveFeature,
  bulkEditorCandidateList
} from "./initialStates";
const bulkEditorSlice = createSlice({
  name: "bulkEditor",
  initialState: {
    ui: bulkEditorUi,
    activeFeature: bulkEditorActiveFeature,
    candidateList: bulkEditorCandidateList
  },
  reducers: {
    updateBulkEditorUi: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        ui: __spreadValues(__spreadValues({}, state.ui), action.payload)
      });
    },
    resetBulkEditorUi: (state) => {
      return __spreadProps(__spreadValues({}, state), {
        ui: bulkEditorUi
      });
    },
    updateBulkEditorActiveFeature: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        activeFeature: __spreadValues(__spreadValues({}, state.activeFeature), action.payload)
      });
    },
    resetBulkEditorActiveFeature: (state) => {
      return __spreadProps(__spreadValues({}, state), {
        activeFeature: bulkEditorActiveFeature
      });
    },
    updateBulkEditorCandidateList: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        candidateList: __spreadValues(__spreadValues({}, state.candidateList), action.payload)
      });
    },
    resetBulkEditorCandidateList: (state) => {
      return __spreadProps(__spreadValues({}, state), {
        candidateList: bulkEditorCandidateList
      });
    }
  }
});
export const {
  updateBulkEditorUi,
  resetBulkEditorUi,
  updateBulkEditorActiveFeature,
  resetBulkEditorActiveFeature,
  updateBulkEditorCandidateList,
  resetBulkEditorCandidateList
} = bulkEditorSlice.actions;
export default bulkEditorSlice.reducer;
export const selectBulkEditorUi = createSelector((state) => state.bulkEditor, (bulkEditor) => bulkEditor.ui);
export const selectBulkEditorActiveFeature = createSelector((state) => state.bulkEditor, (bulkEditor) => bulkEditor.activeFeature);
export const selectBulkEditorCandidateList = createSelector((state) => state.bulkEditor, (bulkEditor) => bulkEditor.candidateList);
export const selectBulkEditorCandidateCount = createSelector((state) => state.bulkEditor, (bulkEditor) => bulkEditor.candidateList.candidates.length);
