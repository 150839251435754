var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { rvshareUI } from "./rvshareInitialState";
const initialState = {
  ui: {
    isDirty: false,
    isEditing: false,
    isSaving: false,
    isLoaded: false,
    loading: true,
    openInflatorCheckModal: false
  },
  rvshare: {
    ui: rvshareUI
  },
  airbnb: {
    addAccountStepOne: false,
    allowAddAccount: false,
    openMappingModal: false,
    openUnlistModal: false,
    openDistributionModal: false,
    openManualAirbnbModal: false,
    unit: null,
    mapping: null
  }
};
const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    updateChannelsUI: (state, action) => {
      return __spreadProps(__spreadValues({}, state), { ui: __spreadValues(__spreadValues({}, state.ui), action.payload) });
    },
    resetChannelsUI: (state) => {
      return __spreadProps(__spreadValues({}, state), { ui: initialState });
    },
    updateChannelsAirbnb: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        airbnb: __spreadValues(__spreadValues({}, state.airbnb), action.payload)
      });
    },
    resetChannelsAirbnb: (state) => {
      return __spreadProps(__spreadValues({}, state), { airbnb: __spreadValues({}, initialState.airbnb) });
    },
    updateChannelsRvShareUI: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        rvshare: {
          ui: __spreadValues(__spreadValues({}, state.rvshare.ui), action.payload)
        }
      });
    },
    resetChannelsRvShareUI: (state) => {
      return __spreadProps(__spreadValues({}, state), { rvshare: { ui: rvshareUI } });
    }
  }
});
export const {
  updateChannelsUI,
  resetChannelsUI,
  updateChannelsRvShareUI,
  resetChannelsRvShareUI,
  updateChannelsAirbnb,
  resetChannelsAirbnb
} = channelsSlice.actions;
export default channelsSlice.reducer;
export const selectChannelsUI = createSelector((state) => state.channels.ui, (channelsUI) => channelsUI);
export const selectChannelsRvShareUI = createSelector((state) => state.channels.rvshare.ui, (rvShareUI) => rvShareUI);
export const selectChannelsAirbnb = createSelector((state) => state.channels.airbnb, (rvShareUI) => rvShareUI);
