var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  activeRoutes: {
    primary: "",
    secondary: "",
    tertiary: ""
  },
  revealMobileNav: false,
  isSearching: false,
  searchTerm: "",
  searchPanelVisible: false,
  searchFilter: "all",
  hideNavbar: false,
  hidePageHeader: false
};
const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    updateNavigation: (state, action) => {
      return __spreadValues(__spreadValues({}, state), action.payload);
    },
    resetNavigation: () => {
      return initialState;
    },
    resetNavGlobalSearch: (state) => {
      return __spreadProps(__spreadValues({}, state), {
        revealMobileNav: false,
        isSearching: false,
        searchTerm: "",
        searchPanelVisible: false,
        searchFilter: "all",
        searchTopResults: null
      });
    },
    updateActiveRoutes: (state, action) => {
      const primary = action.payload.primary || "";
      const secondary = action.payload.secondary || "";
      const tertiary = action.payload.tertiary || "";
      return __spreadProps(__spreadValues({}, state), {
        activeRoutes: { primary, secondary, tertiary }
      });
    },
    resetActiveRoutes: (state) => {
      return __spreadProps(__spreadValues({}, state), { activeRoutes: __spreadValues({}, initialState.activeRoutes) });
    }
  }
});
export const {
  updateActiveRoutes,
  resetActiveRoutes,
  updateNavigation,
  resetNavigation,
  resetNavGlobalSearch
} = navigationSlice.actions;
export default navigationSlice.reducer;
export const selectNavigationState = createSelector((state) => state.navigation, (navigation) => navigation);
