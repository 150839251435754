var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  telephone: "",
  assigneeId: null,
  status: "no_action",
  tag: null,
  bookingType: "guest",
  unitId: null,
  units: []
};
const createOpportunitySlice = createSlice({
  name: "newOpportunity",
  initialState,
  reducers: {
    updateNewOpportunity: (state, action) => {
      return __spreadValues(__spreadValues({}, state), action.payload);
    },
    resetNewOpportunity: (state) => {
      return __spreadValues(__spreadValues({}, state), initialState);
    }
  }
});
export const {
  updateNewOpportunity,
  resetNewOpportunity
} = createOpportunitySlice.actions;
export default createOpportunitySlice.reducer;
export const selectNewOpportunityState = createSelector((state) => state.newOpportunity, (opportunity) => opportunity);
