var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  inboxUiState,
  activeConversationState,
  conversationListState,
  inboxNav,
  inboxSidebar,
  inboxParams,
  inboxFilters,
  pendingMessage
} from "./initialStates";
const unifiedInboxSlice = createSlice({
  name: "unifiedInbox",
  initialState: {
    ui: inboxUiState,
    inboxParams,
    inboxFilters,
    inboxConversationList: conversationListState,
    inboxActiveConversation: activeConversationState,
    inboxNav,
    inboxSidebar,
    pendingMessage
  },
  reducers: {
    updateInboxUi: (state, action) => {
      return __spreadProps(__spreadValues({}, state), { ui: __spreadValues(__spreadValues({}, state.ui), action.payload) });
    },
    resetInboxUi: (state) => {
      return __spreadProps(__spreadValues({}, state), {
        ui: inboxUiState
      });
    },
    updateInboxFilters: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        inboxFilters: __spreadValues(__spreadValues({}, state.inboxFilters), action.payload)
      });
    },
    updateInboxParams: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        inboxParams: __spreadValues(__spreadValues({}, state.inboxParams), action.payload)
      });
    },
    updateInboxConversationList: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        inboxConversationList: __spreadValues(__spreadValues({}, state.inboxConversationList), action.payload)
      });
    },
    updateInboxActiveConversation: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        inboxActiveConversation: __spreadValues(__spreadValues({}, state.inboxActiveConversation), action.payload)
      });
    },
    resetInboxActiveConversation: (state) => {
      return __spreadProps(__spreadValues({}, state), {
        inboxActiveConversation: activeConversationState
      });
    },
    updateInboxSidebar: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        inboxSidebar: __spreadValues(__spreadValues({}, state.inboxSidebar), action.payload)
      });
    },
    resetInboxSidebar: (state) => {
      return __spreadProps(__spreadValues({}, state), {
        inboxSidebar
      });
    },
    updatePendingMessage: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        pendingMessage: __spreadValues(__spreadValues({}, state.pendingMessage), action.payload)
      });
    },
    resetPendingMessage: (state) => {
      return __spreadProps(__spreadValues({}, state), {
        pendingMessage
      });
    }
  }
});
export const {
  updateInboxUi,
  updateInboxParams,
  updateInboxConversationList,
  updateInboxActiveConversation,
  updateInboxFilters,
  resetInboxActiveConversation,
  resetInboxUi,
  updateInboxSidebar,
  resetInboxSidebar,
  updatePendingMessage,
  resetPendingMessage
} = unifiedInboxSlice.actions;
export default unifiedInboxSlice.reducer;
export const selectInboxUIState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.ui);
export const selectInboxParamsState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxParams);
export const selectInboxNavState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxNav);
export const selectInboxConversationListState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxConversationList);
export const selectInboxActiveConversationState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxActiveConversation);
export const selectInboxFiltersState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxFilters);
export const selectInboxConversationCount = createSelector((state) => state.unifiedInbox.inboxConversationList, (conversationList) => conversationList.conversations.length);
export const selectInboxActiveConversationId = createSelector((state) => state.unifiedInbox.inboxActiveConversation, (activeConversation) => activeConversation.id);
export const selectInboxActiveCustomerId = createSelector((state) => state.unifiedInbox.inboxActiveConversation, (activeConversation) => {
  var _a;
  return (_a = activeConversation.customer) == null ? void 0 : _a.id;
});
export const selectInboxActiveThread = createSelector((state) => state.unifiedInbox.inboxActiveConversation, (activeConversation) => activeConversation.activeThread);
export const selectInboxOtherThreads = createSelector((state) => state.unifiedInbox.inboxActiveConversation, (activeConversation) => activeConversation.otherThreads);
export const selectInboxCustomer = createSelector((state) => state.unifiedInbox.inboxActiveConversation, (activeConversation) => activeConversation.customer);
export const selectInboxSidebar = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxSidebar);
export const selectConversationById = (conversationId) => createSelector((state) => state.unifiedInbox.inboxConversationList.conversations, (conversations) => conversations.filter((convo) => convo.id === conversationId)[0]);
export const selectPendingMessage = createSelector((state) => state.unifiedInbox, (inboxState) => inboxState.pendingMessage);
export const selectPendingMessageBody = createSelector((state) => state.unifiedInbox, (inboxState) => inboxState.pendingMessage.body);
export const selectPendingMessageAttachmentPreviews = createSelector((state) => state.unifiedInbox, (inboxState) => inboxState.pendingMessage.attachmentPreviews);
