const employeeRoles = [
  {
    label: "Administrator",
    value: "organization_owner"
  },
  {
    label: "Website Manager",
    value: "brand_owner"
  },
  {
    label: "Property Manager",
    value: "property_manager"
  },
  {
    label: "Property Owner",
    value: "property_contact"
  },
  {
    label: "Housekeeping",
    value: "housekeeping"
  },
  {
    label: "Maintenance",
    value: "maintenance"
  },
  {
    label: "Reservationist",
    value: "reservationist"
  }
];
const payoutFrequencies = [
  {
    label: "Monthly",
    value: "monthly"
  },
  {
    label: "Weekly",
    value: "weekly"
  },
  {
    label: "Bi-weekly",
    value: "bi_weekly"
  },
  {
    label: "Per Reservation",
    value: "per_reservation"
  },
  {
    label: "Per Job",
    value: "per_job"
  }
];
export { employeeRoles, payoutFrequencies };
