var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import loadable from "@loadable/component";
import Notification from "sharedComponents/Notification";
import Navigation from "./components/Navigation";
const SignIn = loadable(() => import("./pages/SignIn"));
const SignUp = loadable(() => import("./pages/SignUp"));
const ForgotPassword = loadable(() => import("./pages/ForgotPassword"));
const Confirmation = loadable(() => import("./pages/Confirmation"));
const Registration = loadable(() => import("./pages/Registration"));
const appStyles = {
  display: "flex",
  flexFlow: "column",
  height: "100%"
};
export default class AuthenticationApp extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return /* @__PURE__ */ React.createElement(Router, {
      basename: "/"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "authenticationApp",
      style: appStyles
    }, /* @__PURE__ */ React.createElement(Notification, null), /* @__PURE__ */ React.createElement(Navigation, __spreadValues({}, this.props)), /* @__PURE__ */ React.createElement("main", {
      className: "authentication"
    }, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
      path: "/sign-in",
      render: (routeProps) => /* @__PURE__ */ React.createElement(SignIn, __spreadValues(__spreadValues({}, this.props), routeProps))
    }), /* @__PURE__ */ React.createElement(Route, {
      path: "/sign-up",
      render: (routeProps) => /* @__PURE__ */ React.createElement(SignUp, __spreadValues(__spreadValues({}, this.props), routeProps))
    }), /* @__PURE__ */ React.createElement(Route, {
      path: "/forgot-password",
      render: (routeProps) => /* @__PURE__ */ React.createElement(ForgotPassword, __spreadValues(__spreadValues({}, this.props), routeProps))
    }), /* @__PURE__ */ React.createElement(Route, {
      path: "/confirmations/new",
      render: (routeProps) => /* @__PURE__ */ React.createElement(Confirmation, __spreadValues(__spreadValues({}, this.props), routeProps))
    }), /* @__PURE__ */ React.createElement(Route, {
      path: "/registrations/edit",
      render: (routeProps) => /* @__PURE__ */ React.createElement(Registration, __spreadValues(__spreadValues({}, this.props), routeProps))
    })))));
  }
}
