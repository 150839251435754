var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ui: {
    cleanUpNewOrder: false
  },
  newWorkOrder: {
    submittable: false,
    unit_id: "",
    assignee_type: "",
    assignee_id: "",
    wo_type: "",
    job_type: "",
    description: "",
    title: "",
    task_types: [],
    requested_by: null,
    booking_id: "",
    isFocused: false,
    date: null,
    time: "",
    amount: ""
  }
};
const workOrdersSlice = createSlice({
  name: "workOrders",
  initialState,
  reducers: {
    updateWorkOrdersUI: (state, action) => {
      return __spreadProps(__spreadValues({}, state), { ui: __spreadValues(__spreadValues({}, state.ui), action.payload) });
    },
    resetWorkOrdersUI: (state) => {
      return __spreadProps(__spreadValues({}, state), { ui: initialState.ui });
    },
    updateNewWorkOrder: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        newWorkOrder: __spreadValues(__spreadValues({}, state.newWorkOrder), action.payload)
      });
    },
    resetNewWorkOrder: (state) => {
      return __spreadProps(__spreadValues({}, state), { newWorkOrder: initialState.newWorkOrder });
    }
  }
});
export const {
  updateWorkOrdersUI,
  resetWorkOrdersUI,
  updateNewWorkOrder,
  resetNewWorkOrder
} = workOrdersSlice.actions;
export default workOrdersSlice.reducer;
