import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const GoogleLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags
}) => {
  const [showLink, setShowLink] = useState(false);

  useEffect(() => {
    setShowLink(
      window.location.hostname === 'www.lvh.me' ||
        window.location.hostname === 'staging.getdirect.io'
    );
  }, []);

  return showLink ? (
    <Navbar.Link
      href="/channels/google"
      variation="secondary"
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.GOOGLE}
    >
      Google
    </Navbar.Link>
  ) : (
    <></>
  );
};

GoogleLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default GoogleLink;
