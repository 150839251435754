import React from 'react';
import { Box, Divider, Navbar } from '@directsoftware/ui-kit-web-admin';
import PermissionComponent from 'PermissionComponent';
import PayoutsAndBalancesLink from 'adminNavbar/accounting/links/payouts-and-balances-link';
import InvoicesLink from 'adminNavbar/accounting/links/invoices-link';
import ReconcileLink from 'adminNavbar/accounting/links/reconcile-link';
import ChartOfAccountsLink from 'adminNavbar/accounting/links/chart-of-accounts-link';
import GeneralLedgerLink from 'adminNavbar/accounting/links/general-ledger-link';
import StatementsLink from 'adminNavbar/accounting/links/statements-link';
import BookingsSummaryLink from 'adminNavbar/accounting/links/bookings-summary-link';
import EarningsSummaryLink from 'adminNavbar/accounting/links/earnings-summary-link';
import BankAccountsLink from 'adminNavbar/accounting/links/bank-accounts-link';
import ReportingLink from 'adminNavbar/accounting/links/reporting-link';

const LinksDesktop = props => {
  return (
    <>
      <Navbar.LinkMenuColumn>
        <PermissionComponent user={props.user} permission="payouts_balances">
          <PayoutsAndBalancesLink {...props} />
        </PermissionComponent>
        <PermissionComponent user={props.user} permission="invoices">
          <InvoicesLink {...props} />
        </PermissionComponent>
        <PermissionComponent user={props.user} permission="reconcile">
          <ReconcileLink {...props} />
        </PermissionComponent>
        <PermissionComponent user={props.user} permission="ledger">
          <ChartOfAccountsLink {...props} />
          <GeneralLedgerLink {...props} />
          <BookingsSummaryLink {...props} />
        </PermissionComponent>
        <StatementsLink {...props} />
        <PermissionComponent user={props.user} permission="earnings_summary">
          <EarningsSummaryLink {...props} />
        </PermissionComponent>
        <PermissionComponent user={props.user} permission="bank_accounts">
          <BankAccountsLink {...props} />
        </PermissionComponent>
        <PermissionComponent user={props.user} permission="view_reporting">
          <Box className="nav-v3-column-divider">
            <Divider />
          </Box>
          <ReportingLink {...props} />
        </PermissionComponent>
      </Navbar.LinkMenuColumn>
    </>
  );
};

export default LinksDesktop;
