import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container, FlexBox, Spacer } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { useDetectMobile } from '../../shared/hooks';
import {
  LISTINGS_NAV_TAGS,
  RESERVATIONS_NAV_TAGS,
  WEBSITE_NAV_TAGS
} from '../../shared/nav';
import ReservationsOverviewSideTabBar from '../navigation/design-system-nav/side-tab-bars/reservations-overview-side-tab-bar';
import ListingsSideTabAnchors from '../navigation/design-system-nav/side-tab-bars/listings-side-tab-anchors';
import {
  propertyChildUnitSideTabAnchors,
  propertyMultiUnitSideTabAnchors,
  propertySingleSideTabAnchors,
  vehicleSideTabAnchors
} from '../../shared/options';
import { selectWebsiteCMSState } from '../redux/slices/websiteCMS';
import WebsiteEditorSideTabs from '../navigation/design-system-nav/side-tab-bars/website-editor-side-tabs';
import { selectUserDetails } from '../redux/slices/userDetails';

const DetailsLayout = ({
  children,
  tabBar,
  property,
  unit,
  isMultiUnit,
  allProps,
  vehicle,
  customer,
  user,
  booking,
  brand
}) => {
  const ref = useRef(null);
  const { isMobile } = useDetectMobile();
  const activeRoutes = useSelector(state => state.navigation.activeRoutes);
  const { cmsVersion } = useSelector(selectWebsiteCMSState);
  const userFromRedux = useSelector(selectUserDetails);

  const getSidebarTabAnchors = () => {
    const pricingSideTabAnchors = {
      stripe: propertySingleSideTabAnchors.pricing,
      lynnbrook: propertySingleSideTabAnchors.pricing.filter(
        obj => obj.label !== 'Security Deposit'
      )
    };
    switch (activeRoutes.tertiary) {
      case LISTINGS_NAV_TAGS.tertiary.BEDS_AND_BATHS:
        return propertyChildUnitSideTabAnchors.bedsAndBaths;
      case LISTINGS_NAV_TAGS.tertiary.PRICING:
        return vehicle
          ? vehicleSideTabAnchors.pricing
          : pricingSideTabAnchors[(allProps?.organization?.payment_processor)];
      case LISTINGS_NAV_TAGS.tertiary.AVAILABILITY:
        if (vehicle) return vehicleSideTabAnchors.availability;
        else return propertySingleSideTabAnchors.availability;
      case LISTINGS_NAV_TAGS.tertiary.PEOPLE_AND_ADMIN:
        if (unit) {
          return propertyChildUnitSideTabAnchors.peopleAndAdmin;
        } else {
          return isMultiUnit
            ? propertyMultiUnitSideTabAnchors.peopleAndAdmin
            : propertySingleSideTabAnchors.peopleAndAdmin;
        }
      case LISTINGS_NAV_TAGS.tertiary.POLICIES_AND_RULES:
        if (vehicle) {
          return vehicleSideTabAnchors.policiesAndRules;
        } else if (unit) {
          return propertyChildUnitSideTabAnchors.policiesAndRules;
        } else {
          return isMultiUnit
            ? propertyMultiUnitSideTabAnchors.policiesAndRules
            : propertySingleSideTabAnchors.policiesAndRules;
        }
      case LISTINGS_NAV_TAGS.tertiary.OVERVIEW:
        if (vehicle) {
          return vehicleSideTabAnchors.overview;
        } else if (unit) {
          return propertyChildUnitSideTabAnchors.overview;
        } else {
          return isMultiUnit
            ? propertyMultiUnitSideTabAnchors.overview
            : propertySingleSideTabAnchors.overview;
        }
      default:
        return null;
    }
  };

  const renderSideBar = () => {
    let sidebar = null;
    switch (activeRoutes.tertiary) {
      case WEBSITE_NAV_TAGS.tertiary.NAVIGATION:
      case WEBSITE_NAV_TAGS.tertiary.CONTACT_INFO:
      case WEBSITE_NAV_TAGS.tertiary.PAGES:
      case WEBSITE_NAV_TAGS.tertiary.CMS:
      case WEBSITE_NAV_TAGS.tertiary.OVERVIEW:
        sidebar =
          cmsVersion === 'v2' ? (
            <WebsiteEditorSideTabs
              brand={brand}
              brandId={brand.id}
              isV2Brand={brand.cms_version === 'v2'}
              organization={userFromRedux.organization}
              tertiaryNavTagOnMount={activeRoutes.tertiary}
              user={userFromRedux}
            />
          ) : null;
        break;
      case RESERVATIONS_NAV_TAGS.tertiary.OVERVIEW:
        sidebar = (
          <ReservationsOverviewSideTabBar
            customer={customer}
            allProps={allProps}
            user={user}
            booking={booking}
            testRef={ref}
          />
        );
        break;
      case LISTINGS_NAV_TAGS.tertiary.PEOPLE_AND_ADMIN:
        if (!vehicle) {
          sidebar = (
            <ListingsSideTabAnchors
              tabs={getSidebarTabAnchors()}
              property={property}
              unit={unit}
              isMultiUnit={isMultiUnit}
              vehichle={vehicle}
              allProps={allProps}
            />
          );
        }
        break;
      case LISTINGS_NAV_TAGS.tertiary.PRICING:
      case LISTINGS_NAV_TAGS.tertiary.AVAILABILITY:
      case LISTINGS_NAV_TAGS.tertiary.POLICIES_AND_RULES:
      case LISTINGS_NAV_TAGS.tertiary.BEDS_AND_BATHS:
      case LISTINGS_NAV_TAGS.tertiary.OVERVIEW:
        sidebar = (
          <ListingsSideTabAnchors
            tabs={getSidebarTabAnchors()}
            property={property}
            unit={unit}
            isMultiUnit={isMultiUnit}
            vehichle={vehicle}
            allProps={allProps}
          />
        );
        break;
      default:
        break;
    }

    return sidebar ? (
      <FlexBox className="details-layout-sidebar">{sidebar}</FlexBox>
    ) : null;
  };

  useEffect(
    () => {
      ref.current.scroll({
        top: 0,
        behavior: isMobile ? 'smooth' : 'instant'
      });
    },
    [activeRoutes]
  );

  useEffect(() => {
    document.body.classList.add('adjust-for-side-tab', 'details-layout');
    return () => {
      document.body.classList.remove('adjust-for-side-tab', 'details-layout');
    };
  }, []);

  return (
    <>
      {tabBar}
      <Container variation="full-width">
        <FlexBox
          flexDirection={isMobile ? 'column' : 'row'}
          paddingLeft={isMobile ? 's' : 'm'}
        >
          {renderSideBar()}
          <div
            ref={ref}
            style={{ flex: 1, paddingRight: isMobile ? 16 : 24 }}
            id="detailsLayoutContent"
            className="details-layout-content"
          >
            <Spacer size="m" />
            {children}
            <Spacer size="xxl" />
          </div>
        </FlexBox>
      </Container>
    </>
  );
};

DetailsLayout.propTypes = {
  children: PropTypes.any,
  tabBar: PropTypes.any
};

export default DetailsLayout;
