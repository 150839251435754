import request from "apiClient";
export default class ExpenseService {
  static index(organizationId, params = {}) {
    return request({
      url: `/api/${organizationId}/expenses`,
      method: "GET",
      params
    });
  }
  static create(organizationId, data) {
    return request({
      url: `/api/${organizationId}/expenses`,
      method: "POST",
      data
    });
  }
  static fetch(organizationId, expenseId) {
    return request({
      url: `/api/${organizationId}/expenses/${expenseId}`,
      method: "GET"
    });
  }
  static delete(organizationId, expenseId) {
    return request({
      url: `/api/${organizationId}/expenses/${expenseId}`,
      method: "DELETE"
    });
  }
  static update(organizationId, expenseId, data) {
    return request({
      url: `/api/${organizationId}/expenses/${expenseId}`,
      method: "PATCH",
      data
    });
  }
}
