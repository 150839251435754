var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import request from "apiClient";
export default class ReportService {
  static index(organizationId, reportType) {
    return request({
      url: `/api/${organizationId}/reports`,
      method: "GET",
      params: { report_type: reportType }
    });
  }
  static new(organizationId, params = {}) {
    return request({
      url: `/api/${organizationId}/reports/new`,
      method: "GET",
      params
    });
  }
  static create(organizationId, params = {}) {
    return request({
      url: `/api/${organizationId}/reports/create`,
      method: "POST",
      data: params
    });
  }
  static possibleReports(organizationId, userId, params = {}) {
    return request({
      url: `/api/${organizationId}/possible_reports`,
      method: "GET",
      params: __spreadValues({ user_id: userId }, params)
    });
  }
  static favorite(organizationId, params = {}) {
    return request({
      url: `/api/${organizationId}/favorite`,
      method: "PUT",
      params
    });
  }
}
