import moment from "moment/moment";
const propertyOwnerYearlyEarningOptions = [];
let i = 0;
while (i < 10) {
  propertyOwnerYearlyEarningOptions.push({
    label: moment().subtract(i, "years").startOf("year").startOf("day").format("YYYY"),
    value: moment().subtract(i, "years").startOf("year").startOf("day")
  });
  if (moment().subtract(i, "years").format("YYYY") === "2021")
    i = 11;
  i++;
}
export { propertyOwnerYearlyEarningOptions };
