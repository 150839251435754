import React, { useEffect } from 'react';
import {
  IconButton,
  IconFontAwesome,
  PageHeader
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { RESERVATIONS_NAV_TAGS } from '../../../../../shared/nav';
import { useDetectMobile } from '../../../../../shared/hooks';
import { updateReservations } from '../../../../redux/slices/reservations';

const ReservationsCalendar = props => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: RESERVATIONS_NAV_TAGS.PRIMARY,
        secondary: RESERVATIONS_NAV_TAGS.secondary.CALENDAR_VIEW
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid">
      <PageHeader.Left>
        Reservations: <span>Calendar</span>
      </PageHeader.Left>
      {isMobile && (
        <PageHeader.Right>
          <IconButton
            onClick={() => {
              dispatch(updateReservations({ showCalendarOptions: true }));
            }}
            variation="secondary"
            appearance="ghost"
            size="dense"
          >
            <IconFontAwesome name="cog" />
          </IconButton>
        </PageHeader.Right>
      )}
    </PageHeader>
  );
};

export default ReservationsCalendar;
