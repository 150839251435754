var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  images: {
    resyncPropertyPhotos: false,
    uploadPropertyPhotos: false,
    uploadUnitPhotos: false,
    uploadVehiclePhotos: false
  },
  details: {
    latitude: null,
    longitude: null,
    openCloneVehicle: false,
    vehicleIsSavingTag: "",
    initialPropsChecked: false
  },
  pricing: {
    openSecurityDepositModal: false,
    openBookingDepositModal: false
  }
};
const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    updateListingImages: (state, action) => {
      return __spreadProps(__spreadValues({}, state), { images: __spreadValues(__spreadValues({}, state.images), action.payload) });
    },
    resetListingImages: (state) => {
      return __spreadProps(__spreadValues({}, state), { images: initialState.images });
    },
    updateListingDetails: (state, action) => {
      return __spreadProps(__spreadValues({}, state), { details: __spreadValues(__spreadValues({}, state.details), action.payload) });
    },
    resetListingDetails: (state) => {
      return __spreadProps(__spreadValues({}, state), { details: initialState.details });
    },
    updateListingPricing: (state, action) => {
      return __spreadProps(__spreadValues({}, state), { pricing: __spreadValues(__spreadValues({}, state.pricing), action.payload) });
    },
    resetListingPricing: (state) => {
      return __spreadProps(__spreadValues({}, state), { pricing: initialState.pricing });
    }
  }
});
export const {
  updateListingImages,
  resetListingImages,
  updateListingDetails,
  resetListingDetails,
  updateListingPricing,
  resetListingPricing
} = listingsSlice.actions;
export default listingsSlice.reducer;
export const selectListingImagesState = createSelector((state) => state.listings, (listings) => listings.images);
export const selectListingDetailsState = createSelector((state) => state.listings, (listings) => listings.details);
export const selectListingPricingState = createSelector((state) => state.listings, (listings) => listings.pricing);
