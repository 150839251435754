import React from 'react';
import { VisibilityHelper, Navbar } from '@directsoftware/ui-kit-web-admin';
import { MY_ACCOUNT_NAV_TAGS } from 'sharedNav';
import HelpIconLink from 'adminNavbar/support/links/help-icon-link';
import { globalSearchOptions } from 'sharedOptions';
import MyAccountDesktopLinkMenu from './link-menus/my-account/my-account-desktop-link-menu';
import GlobalSearchNav from '../../../global-search-nav/global-search-nav';

const RvFleetRight = props => {
  return (
    <Navbar.Right
      onMobileClick={props.onMobileClick}
      toggleMobileIcon={props.toggleMobileIcon}
    >
      {(props.user.role === 4 ||
        props.user.role === 5 ||
        props.user.role === 7) && (
        <GlobalSearchNav
          searchModels={globalSearchOptions.fleet_owner.models}
          searchTips={globalSearchOptions.fleet_owner.tips}
          openCustomerSidebar={props.openCustomerSidebar}
        />
      )}
      <VisibilityHelper showStartingFrom="large-desktop">
        <HelpIconLink />
        <MyAccountDesktopLinkMenu
          {...props}
          activeRoutes={props.activeRoutes}
          navTags={MY_ACCOUNT_NAV_TAGS}
        />
      </VisibilityHelper>
    </Navbar.Right>
  );
};

RvFleetRight.propTypes = {};

RvFleetRight.defaultProps = {};

export default RvFleetRight;
