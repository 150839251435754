import request from "apiClient";
export default class StripeService {
  static update(organizationId, accountId, data) {
    return request({
      url: `/api/${organizationId}/trust_accounts/${accountId}`,
      method: "PATCH",
      data
    });
  }
  static getTrustAccounts(organizationId) {
    return request({
      url: `/api/${organizationId}/trust_accounts`,
      method: "GET"
    });
  }
  static getEmployeeAccount(organizationId, employeeId) {
    return request({
      url: `/api/${organizationId}/employee_stripe_accounts/${employeeId}`,
      method: "GET"
    });
  }
  static createEmployeeAccount(organizationId, employeeId, data) {
    return request({
      url: `/api/${organizationId}/employee_stripe_accounts/${employeeId}`,
      method: "POST",
      data
    });
  }
  static updateEmployeeAccount(organizationId, employeeId, data) {
    return request({
      url: `/api/${organizationId}/employee_stripe_accounts/${employeeId}`,
      method: "PATCH",
      data
    });
  }
  static createBankAccount(organizationId, employeeId, data) {
    return request({
      url: `/api/${organizationId}/employee_bank_accounts/${employeeId}`,
      method: "POST",
      data
    });
  }
  static updateBankAccount(organizationId, employeeId, data) {
    return request({
      url: `/api/${organizationId}/employee_bank_accounts/${employeeId}`,
      method: "PATCH",
      data
    });
  }
  static listStripeBalances(organizationId, urlParamsString = "") {
    return request({
      url: `/api/${organizationId}/stripe_balances${urlParamsString}`,
      method: "GET"
    });
  }
  static getStripeBalance(organizationId, stripeConnectAccountId) {
    return request({
      url: `/api/${organizationId}/stripe_balances/${stripeConnectAccountId}`,
      method: "GET"
    });
  }
  static redirectToStripeOnboarding(organizationId, employeeId, data) {
    return request({
      url: `/api/${organizationId}/stripe_connect_onboarding/${employeeId}/`,
      method: "PATCH",
      data
    });
  }
  static getStripeBillingSession(organizationId, userId, data) {
    return request({
      url: `/api/${organizationId}/stripe_billing_dashboard/${userId}`,
      method: "PATCH",
      data
    });
  }
  static getStripeCheckoutSession(data) {
    return request({
      url: `/create_stripe_checkout_session`,
      method: "PATCH",
      data
    });
  }
  static getStripeUpgradeSession(organizationId, userId, data) {
    return request({
      url: `/api/${organizationId}/stripe_upgrade_session/${userId}`,
      method: "PATCH",
      data
    });
  }
  static listStripeCustomerCharges(organizationId, customerId, bookingCode) {
    return request({
      url: `/api/${organizationId}/stripe/charges/${customerId}?booking_code=${bookingCode}`,
      method: "GET"
    });
  }
  static cancelSubscription(organizationId, subId) {
    return request({
      url: `/api/${organizationId}/subscriptions/${subId}/cancel`,
      method: "PATCH"
    });
  }
}
