import React from 'react';
import { Box, Navbar, Spacer } from '@directsoftware/ui-kit-web-admin';
import VehiclesLink from 'adminNavbar/listings/links/vehicles-link';
import PortfoliosLink from 'adminNavbar/listings/links/portfolios-link';
import SubPortfoliosLink from 'adminNavbar/listings/links/sub-portfolios-link';
import UnitGroupsLink from 'adminNavbar/listings/links/unit-groups-link';
import RateGroupsLink from 'adminNavbar/listings/links/rate-groups-link';
import PromotionsLink from 'adminNavbar/listings/links/promotions-link';
import VehiclesBulkEditLink from 'adminNavbar/listings/links/vehicles-bulk-edit-link';
import BulkSettingsLink from 'adminNavbar/listings/links/bulk-settings-link';
import VehicleListReportLink from 'adminNavbar/listings/links/vehicle-list-report-link';
import { FeatureTierDisplay } from 'adminComponents/FeatureTierDisplay';
import PermissionComponent from '../../../../../../../PermissionComponent';

const LinksDesktop = props => {
  return (
    <>
      <Navbar.LinkMenuColumn addRightBorder>
        <VehiclesLink {...props} variation="featured" />
        {(props.user.permissions.ledger ||
          props.user.permissions.view_site_editor ||
          props.user.permissions.site_editor_auto_emails) && (
          <PermissionComponent user={props.user} permission="ledger">
            <Box style={{ paddingLeft: 48 }}>
              <BulkSettingsLink {...props} />
            </Box>
            <Spacer size="xxs" />
          </PermissionComponent>
        )}
        {props.organization.organization_type === 'rv_fleet' && (
          <>
            <Box style={{ paddingLeft: 48 }}>
              <VehiclesBulkEditLink {...props} />
            </Box>
            <Box style={{ paddingLeft: 48 }}>
              <VehicleListReportLink
                closeMobileNav={props.closeMobileNav}
                closeMenuPanel={props.closeMenuPanel}
              />
            </Box>
          </>
        )}
      </Navbar.LinkMenuColumn>
      <Navbar.LinkMenuColumn>
        <Navbar.LinkMenuGroup groupTitle="Segmentations">
          <PortfoliosLink {...props} />
          <SubPortfoliosLink {...props} />
          <UnitGroupsLink {...props} />
        </Navbar.LinkMenuGroup>
      </Navbar.LinkMenuColumn>
      <FeatureTierDisplay allowedTiers={['freemium', 'premium']}>
        <Navbar.LinkMenuColumn>
          <Navbar.LinkMenuGroup groupTitle="Other Features">
            <RateGroupsLink {...props} />
            <PromotionsLink {...props} />
          </Navbar.LinkMenuGroup>
        </Navbar.LinkMenuColumn>
      </FeatureTierDisplay>
    </>
  );
};

export default LinksDesktop;
