import React from 'react';
import { InputMoney, TextInput } from 'adminComponents';
import SimpleSelect from 'adminComponents/Selects/SimpleSelect';
import { nativeSymbolFromValue } from 'sharedComponents/currency-display';
import find from 'lodash/find';
import {
  CallToActionButton,
  FormField,
  FormFieldGridRow, InputSelect
} from '@directsoftware/ui-kit-web-admin';

const ExpensesRow = ({
  currency,
  expenses,
  deleteExpense,
  organization,
  useDesignKit
}) => {
  const styles = {
    fullWidth: {
      width: '100%',
      marginBottom: '5%'
    },
    symbol: {
      height: '36px',
      padding: '8px'
    },
    padded: {
      padding: '5px'
    },
    paddedWidth: {
      width: '30%',
      padding: '5px'
    }
  };

  const expenseOptions = [
    { value: 0, label: 'Company' },
    { value: 1, label: 'Unit' }
  ];

  const removeExpense = (e, expense, index) => {
    e.preventDefault();
    deleteExpense(expense, index);
  };

  const setDefaultValue = (options, employeeId) => {
    return options.filter(option => option.value === employeeId);
  };

  return expenses.map((expense, index) =>
    useDesignKit ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          borderBottomColor: '#e5e2e2',
          borderBottomWidth: 1,
          borderBottomStyle: 'solid'
        }}
      >
        <div style={{ flex: 1 }}>
          <FormFieldGridRow
            formFields={[
              {
                component: {
                  inputProps: {
                    value: find(
                      expenseOptions,
                      option => option.value === expense.expense_to
                    ),
                    onChange: option => onChange('expense_to', option.value),
                    options: expenseOptions,
                    isDisabled: true
                  },
                  inputType: 'select',
                  labelText: 'Expense to',
                  labelHtmlFor: 'expense_to'
                }
              },
              {
                component: (
                  <FormField
                    labelText={'Amount'}
                    labelHtmlFor={'amount'}
                    inputType={'currency'}
                    inputProps={{
                      value: expense.amount,
                      isDisabled: true,
                      currencyPrefix: nativeSymbolFromValue(organization.currency)
                    }}
                  />
                )
              }
            ]}
          />
          <FormFieldGridRow
            formFields={[
              expense.expense_to == 0 ? (
                {
                  component: {
                    inputProps: {
                      value: organization.name,
                      isDisabled: true
                    },
                    inputType: 'text',
                    labelText: 'Business'
                  }
                }
              ) : (
                <SimpleSelect
                  label="Unit"
                  selectData={{
                    model: 'Unit',
                    select: ['id', 'name']
                  }}
                  onSelect={selection => {
                    setUnitId(selection.id);
                  }}
                  defaultValue={options =>
                    setDefaultValue(options, expense.unit_id)
                  }
                  isDisabled
                  useDesignKit
                />
              )
            ]}
          />
          <FormFieldGridRow
            formFields={[
              <FormField
                labelText="Memo"
                labelHtmlFor="memo"
                inputType="textarea"
                inputProps={{
                  value: expense.memo,
                  isDisabled: true
                }}
              />
            ]}
          />
        </div>
        <div
          style={{
            padding: '5px',
            paddingLeft: '10px',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CallToActionButton onClick={e => removeExpense(e, expense, index)}>
            Remove
          </CallToActionButton>
        </div>
      </div>
    ) : (
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={styles.paddedWidth}>
          <label htmlFor="expense_to">Expense to</label>
          <InputSelect
            name="expense_to"
            options={expenseOptions}
            value={find(
              expenseOptions,
              option => option.value === expense.expense_to
            )}
            isDisabled
          />
        </div>
        {expense.expense_to == 0 ? (
          <div style={styles.paddedWidth}>
            <TextInput
              label="Business"
              value={organization.name}
              style={{ height: '36px' }}
              disabled
            />
          </div>
        ) : (
          <div className="margin-right" style={styles.paddedWidth}>
            <SimpleSelect
              label="Unit"
              selectData={{
                model: 'Unit',
                select: ['id', 'name']
              }}
              onSelect={selection => {
                setUnitId(selection.id);
              }}
              defaultValue={options =>
                setDefaultValue(options, expense.unit_id)
              }
              isDisabled
            />
          </div>
        )}
        <div style={styles.paddedWidth}>
          <InputMoney
            name="amount"
            label="Amount"
            symbolStyles={styles.symbol}
            setMoney={selection => {
              setAmount(selection);
            }}
            inputStyles={{ height: '36px' }}
            value={expense.amount}
            currency={currency}
            disabled
          />
        </div>
        <div style={styles.paddedWidth}>
          <TextInput
            label="Memo"
            onChange={(_, value) => {
              setMemo(value);
            }}
            value={expense.memo}
            style={{ height: '36px' }}
            disabled
          />
        </div>
        <div
          style={{ display: 'flex', alignItems: 'flex-end', padding: '5px' }}
        >
          <button
            className="bill-form-button"
            onClick={e => removeExpense(e, expense, index)}
          >
            Remove
          </button>
        </div>
      </section>
    )
  );
};

export default ExpensesRow;
