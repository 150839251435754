var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  leads: {
    openLeadModal: false
  },
  ui: {
    openAddUnitsModal: false
  }
};
const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    updatePeopleLeads: (state, action) => {
      return __spreadProps(__spreadValues({}, state), { leads: __spreadValues(__spreadValues({}, state.leads), action.payload) });
    },
    resetPeopleLeads: (state) => {
      return __spreadProps(__spreadValues({}, state), { leads: initialState.leads });
    },
    updatePeopleUi: (state, action) => {
      return __spreadProps(__spreadValues({}, state), { ui: __spreadValues(__spreadValues({}, state.ui), action.payload) });
    },
    resetPeopleUi: (state) => {
      return __spreadProps(__spreadValues({}, state), { ui: initialState.ui });
    }
  }
});
export const {
  updatePeopleLeads,
  resetPeopleLeads,
  updatePeopleUi,
  resetPeopleUi
} = peopleSlice.actions;
export default peopleSlice.reducer;
export const selectPeopleLeadsState = createSelector((state) => state.people, (people) => people.leads);
