import React, { useMemo, useState } from 'react';
import { InputMoney, TextInput } from 'adminComponents';
import SimpleSelect from 'adminComponents/Selects/SimpleSelect';
import { nativeSymbolFromValue } from 'sharedComponents/currency-display';
import {
  CallToActionButton,
  FormField,
  FormFieldGridRow,
  InputSelect
} from '@directsoftware/ui-kit-web-admin';
import find from 'lodash/find';

const AddExpenseRow = ({
  addExpense,
  currency,
  organization,
  useDesignKit
}) => {
  const [expenseTo, setExpenseTo] = useState('');
  const [unitId, setUnitId] = useState('');
  const [amount, setAmount] = useState('');
  const [memo, setMemo] = useState('');

  const expenseOptions = [
    { value: 0, label: 'Company' },
    { value: 1, label: 'Unit' }
  ];

  const selectedExpenseTo = useMemo(
    () => {
      const tempValue =
        find(expenseOptions, option => option.value === expenseTo) || {};
      return tempValue;
    },
    [expenseTo]
  );

  const styles = {
    fullWidth: {
      width: '100%',
      marginBottom: '5%'
    },
    symbol: {
      height: '36px',
      padding: '8px'
    }
  };

  const customStyles = {
    menu: base => ({
      ...base,
      textAlign: 'left'
    })
  };

  const updateExpense = expense => {
    addExpense(expense);
    setExpenseTo('');
    setAmount('');
    setUnitId('');
    setMemo('');
  };

  const submitExpense = e => {
    e.preventDefault();

    updateExpense({
      expense_to: expenseTo,
      unit_id: unitId,
      amount,
      memo
    });
  };

  const renderAddButton = () => {
    if (useDesignKit) {
      return (
        <CallToActionButton
          isDisabled={
            expenseTo === '' ||
            (expenseTo === 1 && unitId === '') ||
            amount === ''
          }
          onClick={e => submitExpense(e)}
        >
          Add
        </CallToActionButton>
      );
    }
    if (
      expenseTo === '' ||
      (expenseTo === 1 && unitId === '') ||
      amount === ''
    ) {
      return (
        <button
          className="bill-form-button-disabled"
          onClick={e => submitExpense(e)}
        >
          Add
        </button>
      );
    } else {
      return (
        <button className="bill-form-button" onClick={e => submitExpense(e)}>
          Add
        </button>
      );
    }
  };

  return useDesignKit ? (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: 1, marginRight: '22px' }}>
        <FormFieldGridRow
          formFields={[
            {
              component: (
                <FormField
                  inputProps={{
                    value: selectedExpenseTo,
                    onChange: option => setExpenseTo(option.value),
                    options: expenseOptions
                  }}
                  inputType="select"
                  labelText="Expense to"
                  labelHtmlFor="expense_to"
                />
              )
            },
            {
              component: (
                <FormField
                  labelText="Amount"
                  labelHtmlFor="amount"
                  inputType="currency"
                  inputProps={{
                    value: amount,
                    onValueChange: (value, name, values) => {
                      setAmount(value);
                    },
                    currencyPrefix: nativeSymbolFromValue(currency)
                  }}
                />
              )
            }
          ]}
        />

        <FormFieldGridRow
          formFields={[
            expenseTo === 1 || expenseTo === '' ? (
              <SimpleSelect
                label="Unit"
                selectData={{
                  model: 'Unit',
                  select: ['id', 'name'],
                  order: 'name'
                }}
                onSelect={selection => {
                  setUnitId(selection.id);
                }}
                defaultValue={options => {
                  return options.find(o => o.value === unitId);
                }}
                isSearchable
                isDisabled={expenseTo === ''}
                useDesignKit
              />
            ) : (
              {
                component: {
                  inputProps: {
                    value: organization.name,
                    disabled: true
                  },
                  inputType: 'text',
                  labelText: 'Company'
                }
              }
            )
          ]}
        />
        <FormFieldGridRow
          formFields={[
            <FormField
              labelText="Memo"
              labelHtmlFor="memo"
              inputType="textarea"
              inputProps={{
                value: memo,
                onChange: event => {
                  setMemo(event.target.value);
                }
              }}
            />
          ]}
        />
      </div>
      <div
        style={{
          padding: '5px',
          paddingLeft: '10px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {renderAddButton()}
      </div>
    </div>
  ) : (
    <section style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ width: '25%', padding: '5px' }}>
        <label htmlFor="expense_to">Expense to</label>
        <InputSelect
          name="expense_to"
          options={expenseOptions}
          onChange={selection => {
            setExpenseTo(selection.value);
          }}
          styles={customStyles}
        />
      </div>
      {expenseTo === 1 || expenseTo === '' ? (
        <div className="margin-right" style={{ width: '25%', padding: '5px' }}>
          <SimpleSelect
            label="Unit"
            selectData={{
              model: 'Unit',
              select: ['id', 'name'],
              order: 'name'
            }}
            onSelect={selection => {
              setUnitId(selection.id);
            }}
            defaultValue={options => {
              return options.find(o => o.value === unitId);
            }}
            isSearchable
            isDisabled={expenseTo === ''}
          />
        </div>
      ) : (
        <div style={{ width: '25%', padding: '5px' }}>
          <TextInput
            label="Company"
            value={organization.name}
            style={{ height: '36px' }}
            disabled
          />
        </div>
      )}
      <div style={{ padding: '5px' }}>
        <InputMoney
          name="amount"
          label="Amount"
          symbolStyles={styles.symbol}
          setMoney={selection => {
            setAmount(selection);
          }}
          inputStyles={{ height: '36px' }}
          value={amount}
          currency={currency}
        />
      </div>
      <div style={{ padding: '5px' }}>
        <TextInput
          label="Memo"
          onChange={(_, value) => {
            setMemo(value);
          }}
          value={memo}
          style={{ height: '36px' }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end', padding: '5px' }}>
        {renderAddButton()}
      </div>
    </section>
  );
};

export default AddExpenseRow;
