var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import request from "apiClient";
export default class EmployeeService {
  static units(organizationID, employeeID, queryString) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}/units${queryString}`,
      method: "GET"
    });
  }
  static unitsContracts(organizationID, employeeID, queryString) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}/units_contracts${queryString}`,
      method: "GET"
    });
  }
  static toggleUnit(organizationID, employeeID, id, className) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}/units`,
      method: "PATCH",
      data: {
        klass_id: id,
        klass_name: className
      }
    });
  }
  static updateUnitMargin(organizationID, employeeID, unitID, margin) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}/unit_margin`,
      method: "PATCH",
      data: {
        unit_id: unitID,
        margin
      }
    });
  }
  static portfolios(organizationID, employeeID, queryString) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}/portfolios${queryString}`,
      method: "GET"
    });
  }
  static togglePortfolio(organizationID, employeeID, portfolioID) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}/portfolios`,
      method: "PATCH",
      data: { portfolio_id: portfolioID }
    });
  }
  static get(organizationID, employeeID) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}`,
      method: "GET"
    });
  }
  static update(organizationID, employeeID, data) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}`,
      method: "PATCH",
      data
    });
  }
  static updateContract(organizationID, employeeID, contractID, data) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}/contract_periods/${contractID}`,
      method: "PATCH",
      data
    });
  }
  static createContract(organizationID, employeeID, id, className, data) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}/units/${id}/contract_periods`,
      method: "POST",
      data: __spreadProps(__spreadValues({}, data), { class_name: className })
    });
  }
  static destroyContract(organizationID, employeeID, contractID) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}/contract_periods/${contractID}`,
      method: "DELETE"
    });
  }
  static setActive(organizationID, employeeID, active) {
    return request({
      url: `/api/${organizationID}/employees/${employeeID}/active`,
      method: "PUT",
      data: { active }
    });
  }
}
