var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  parentId: "",
  companyType: "",
  currency: "",
  description: "",
  language: "",
  name: "",
  organizationType: "",
  subdomain: "",
  adrStreet: "",
  adrUnit: "",
  adrCity: "",
  adrState: "",
  adrCountry: "",
  adrPostalCode: "",
  termsAccepted: false,
  ccAccepted: false,
  tier: "",
  interval: "",
  submitting: false,
  brandName: "",
  brandDescription: "",
  paymentProcessor: ""
};
const createOrganizationSlice = createSlice({
  name: "newOrganization",
  initialState,
  reducers: {
    updateNewOrganization: (state, action) => {
      return __spreadValues(__spreadValues({}, state), action.payload);
    },
    resetNewOrganization: (state) => {
      return __spreadValues(__spreadValues({}, state), initialState);
    }
  }
});
export const {
  updateNewOrganization,
  resetNewOrganization
} = createOrganizationSlice.actions;
export default createOrganizationSlice.reducer;
export const selectNewOrganization = createSelector((state) => state.newOrganization, (organization) => organization);
