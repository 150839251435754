var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import "react-dates/initialize";
import loadable from "@loadable/component";
const Notification = loadable(() => import("sharedComponents/Notification"));
const BookingsTable = loadable(() => import("./pages/BookingsTable"));
const UsersTable = loadable(() => import("./pages/UsersTable"));
const OrganizationsTable = loadable(() => import("./pages/OrganizationsTable"));
const PartnerAccessOrganizationsTable = loadable(() => import("./pages/PartnerAccessOrganizationsTable"));
const PartnerAccessesTable = loadable(() => import("./pages/PartnerAccessesTable"));
const InvoicesTable = loadable(() => import("./pages/InvoicesTable"));
const RefundsTable = loadable(() => import("./pages/RefundsTable"));
const ChannelMappingsTable = loadable(() => import("./pages/ChannelMappingsTable"));
const VehicleMappingsTable = loadable(() => import("./pages/VehicleMappingsTable/VehicleMappingsTable"));
const VehiclesTable = loadable(() => import("./pages/VehiclesTable/VehiclesTable"));
const CreateAStatement = loadable(() => import("./pages/CreateAStatement"));
const StripeBankAccounts = loadable(() => import("./pages/StripeBankAccounts"));
const EmployeesTable = loadable(() => import("./pages/EmployeesTable"));
const CheckInInstructionTable = loadable(() => import("./pages/CheckInInstructionsTable"));
const CreateALynnbrookAccount = loadable(() => import("./pages/CreateALynnbrookAccount"));
const LynnbrookAccountsTable = loadable(() => import("./pages/LynnbrookAccountsTable"));
const EditLynnbrookAccount = loadable(() => import("./pages/EditLynnbrookAccount/EditLynnbrookAccount"));
const DirectAdminApp = (props) => {
  return /* @__PURE__ */ React.createElement(Router, {
    basename: "/admin/view"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "directApp"
  }, /* @__PURE__ */ React.createElement(Notification, null), /* @__PURE__ */ React.createElement("nav", {
    className: "new-navigation-wrapper"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "new-navigation blue-grey lighten-5"
  }, /* @__PURE__ */ React.createElement("ul", {
    className: "new-navigation-ul"
  }, /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/bookings",
    className: "black-text"
  }, "Upcoming Bookings")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/users",
    className: "black-text"
  }, "Users")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/organizations",
    className: "black-text"
  }, "Organizations")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/partner_access_organizations",
    className: "black-text"
  }, "Partner Access Organizations")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/partner_accesses",
    className: "black-text"
  }, "Partner Accesses")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/invoices",
    className: "black-text"
  }, "Invoices")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/refunds",
    className: "black-text"
  }, "Refunds")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/channel_mappings",
    className: "black-text"
  }, "VRBO Channel Mappings")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/vehicle_mappings",
    className: "black-text"
  }, "RVshare Channel Mappings")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/vehicles",
    className: "black-text"
  }, "Vehicles")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/statements",
    className: "black-text"
  }, "Create a Statement")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/employees",
    className: "black-text"
  }, "Employees")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/stripe_bank_accounts",
    className: "black-text"
  }, "Stripe Bank Account")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/check_in_instructions",
    className: "black-text"
  }, "Check In Instructions")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/create_lynnbrook_account",
    className: "black-text"
  }, "Create a Lynnbrook Account")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/lynnbrook_accounts_table",
    className: "black-text"
  }, "Lynnbrook Accounts"))))), /* @__PURE__ */ React.createElement("main", {
    className: "directAdmin content"
  }, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/bookings",
    render: (routeProps) => /* @__PURE__ */ React.createElement(BookingsTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/users",
    render: (routeProps) => /* @__PURE__ */ React.createElement(UsersTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/organizations",
    render: (routeProps) => /* @__PURE__ */ React.createElement(OrganizationsTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/partner_access_organizations",
    render: (routeProps) => /* @__PURE__ */ React.createElement(PartnerAccessOrganizationsTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/partner_accesses",
    render: (routeProps) => /* @__PURE__ */ React.createElement(PartnerAccessesTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/invoices",
    render: (routeProps) => /* @__PURE__ */ React.createElement(InvoicesTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/refunds",
    render: (routeProps) => /* @__PURE__ */ React.createElement(RefundsTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/channel_mappings",
    render: (routeProps) => /* @__PURE__ */ React.createElement(ChannelMappingsTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/vehicle_mappings",
    render: (routeProps) => /* @__PURE__ */ React.createElement(VehicleMappingsTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/vehicles",
    render: (routeProps) => /* @__PURE__ */ React.createElement(VehiclesTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/statements",
    render: (routeProps) => /* @__PURE__ */ React.createElement(CreateAStatement, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/employees",
    render: (routeProps) => /* @__PURE__ */ React.createElement(EmployeesTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/stripe_bank_accounts",
    render: (routeProps) => /* @__PURE__ */ React.createElement(StripeBankAccounts, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/check_in_instructions",
    render: (routeProps) => /* @__PURE__ */ React.createElement(CheckInInstructionTable, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/create_lynnbrook_account",
    render: (routeProps) => /* @__PURE__ */ React.createElement(CreateALynnbrookAccount, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/edit_lynnbrook_account/:accountId",
    render: (routeProps) => /* @__PURE__ */ React.createElement(EditLynnbrookAccount, __spreadValues(__spreadValues({}, props), routeProps))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/lynnbrook_accounts_table",
    render: (routeProps) => /* @__PURE__ */ React.createElement(LynnbrookAccountsTable, __spreadValues(__spreadValues({}, props), routeProps))
  })))));
};
export default DirectAdminApp;
