import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const VehiclesLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  variation,
  organization
}) => {
  const [showVehicleLink, setShowVehicleLink] = useState(false);

  useEffect(() => {
    setShowVehicleLink(organization.organization_type === 'rv_fleet');
  }, []);

  return showVehicleLink ? (
    <>
      <Navbar.Link
        href="/vehicles"
        variation={variation}
        featuredProps={{
          iconName: 'rv',
          description: 'Motor homes, campers, etc.'
        }}
        onClick={() => {
          closeMenuPanel();
          if (closeMobileNav)
            // desktop links wont have this
            closeMobileNav();
        }}
        isActive={activeRoutes.secondary === navTags.secondary.VEHICLES}
      >
        Recreational Vehicles
      </Navbar.Link>
    </>
  ) : (
    <></>
  );
};

VehiclesLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object,
  variation: PropTypes.string
};

VehiclesLink.defaultProps = {
  variation: 'secondary'
};

export default VehiclesLink;
