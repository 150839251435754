var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  brand_id: "",
  booking_range: null,
  booking_type: "",
  booking_type_name: "",
  confirmed: true,
  customer_id: null,
  customer_email: "",
  customer_name: "",
  customer_telephone: "",
  customer_addr_line_one: "",
  customer_addr_line_two: "",
  customer_city: "",
  customer_state: "",
  customer_country: "",
  customer_zip: "",
  listings: [],
  listing_id: "",
  listing_name: "",
  bookable_id: null,
  total: 0,
  channel_id: 0,
  sendConfirmation: true,
  quote_id: null,
  channel_fee_payable: null,
  owner: null,
  owner_will_self_clean: false,
  post_checkout_cleaning_enabled: false,
  processing: false,
  travelInsuranceAdded: false,
  vehicle_booking: false
};
const createBookingSlice = createSlice({
  name: "newBooking",
  initialState,
  reducers: {
    updateNewBooking: (state, action) => {
      return __spreadValues(__spreadValues({}, state), action.payload);
    },
    setProcessing: (state, action) => {
      state.processing = action.payload;
    },
    resetNewBooking: (state) => {
      return __spreadValues(__spreadValues({}, state), initialState);
    }
  }
});
export const {
  setProcessing,
  updateNewBooking,
  resetNewBooking
} = createBookingSlice.actions;
export default createBookingSlice.reducer;
export const selectBookingStepOneState = createSelector((state) => state.newBooking, (booking) => ({
  brand_id: booking.brand_id,
  listings: booking.listings,
  listing_id: booking.listing_id,
  listing_name: booking.listing_name,
  booking_type: booking.booking_type,
  booking_type_name: booking.booking_type_name,
  confirmed: booking.confirmed,
  bookable_id: booking.bookable_id,
  booking_range: booking.booking_range,
  owner: booking.owner,
  vehicle_booking: booking.vehicle_booking
}));
