var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  details: {
    sendCheckIn: false,
    sendPaymentRequest: false,
    markGuestCardInvalid: false,
    cancelInactiveCard: false,
    unarchiveBooking: false,
    uncancelBooking: false,
    deleteBooking: false,
    cancelBooking: false,
    isLoading: true,
    clickPrintButton: false
  },
  showCalendarOptions: false
};
const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  reducers: {
    updateReservations: (state, action) => {
      return __spreadValues(__spreadValues({}, state), action.payload);
    },
    resetReservations: (state) => {
      return __spreadValues(__spreadValues({}, state), initialState);
    },
    updateReservationDetails: (state, action) => {
      return __spreadProps(__spreadValues({}, state), { details: __spreadValues(__spreadValues({}, state.details), action.payload) });
    },
    resetReservationDetails: (state) => {
      return __spreadProps(__spreadValues({}, state), { details: initialState.details });
    }
  }
});
export const {
  updateReservationDetails,
  resetReservationDetails,
  updateReservations,
  resetReservations
} = reservationsSlice.actions;
export default reservationsSlice.reducer;
export const selectReservationsDetailsState = createSelector((state) => state.reservations, (reservations) => reservations.details);
