var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import request from "apiClient";
function getCustomer(orgId, customerId) {
  return request({
    url: `/api/${orgId}/sidebar/customers/${customerId}`,
    method: "GET"
  });
}
function editCustomer(orgId, customerId, data) {
  return request({
    url: `/api/${orgId}/sidebar/customers/${customerId}`,
    method: "PUT",
    data
  });
}
function getCustomerConversations(orgId, customerId, user) {
  const urlParams = user ? `?current_user_id=${user.id}&current_user_type=${user.type}` : "";
  return request({
    url: `/api/${orgId}/sidebar/customers/${customerId}/conversations${urlParams}`,
    method: "GET"
  });
}
function fetchConversation(orgId, customerId, conversationId) {
  return request({
    url: `/api/${orgId}/sidebar/customers/${customerId}/conversations/${conversationId}`,
    method: "GET"
  });
}
function getCustomerMessageThread(orgId, customerId, conversationId) {
  return request({
    url: `/api/${orgId}/sidebar/customers/${customerId}/conversations/${conversationId}/messages`,
    method: "GET"
  });
}
function createNewMessage(orgId, customerId, conversationId, data) {
  return request({
    url: `/api/${orgId}/sidebar/customers/${customerId}/conversations/${conversationId}/messages`,
    method: "POST",
    data
  });
}
function getCustomerOpportunities(orgId, customerId) {
  return request({
    url: `/api/${orgId}/sidebar/customers/${customerId}/opportunities`,
    method: "GET"
  });
}
function getCustomerNotes(orgId, customerId) {
  return request({
    url: `/api/${orgId}/sidebar/customers/${customerId}/notes`,
    method: "GET"
  });
}
function getCustomerReservations(orgId, customerId) {
  return request({
    url: `/api/${orgId}/sidebar/customers/${customerId}/reservations`,
    method: "GET"
  });
}
function getCustomerReviews(orgId, customerId) {
  return request({
    url: `/api/${orgId}/sidebar/customers/${customerId}/reviews`,
    method: "GET"
  });
}
const sendPreApproval = (organizationId, customerId, conversationId) => __async(this, null, function* () {
  return request({
    url: `/api/${organizationId}/sidebar/customers/${customerId}/conversations/${conversationId}/pre-approve`,
    method: "POST"
  });
});
const CustomerSidebarService = {
  getCustomer,
  editCustomer,
  getCustomerConversations,
  getCustomerMessageThread,
  createNewMessage,
  getCustomerOpportunities,
  getCustomerNotes,
  getCustomerReservations,
  fetchConversation,
  sendPreApproval,
  getCustomerReviews
};
export default CustomerSidebarService;
