var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  cmsVersion: null,
  detailsLayout: false,
  pageBuilder: {
    pageSlices: [],
    isDirty: false,
    isSaved: false,
    isSaving: false,
    isHomePage: false,
    sliceAction: null,
    jumpToSliceAction: null,
    data: {
      meta_title: null,
      meta_description: null,
      content: null,
      description: null,
      featured: null,
      slug: null,
      payload: null,
      published: null,
      published_at: null,
      template: null,
      title: null,
      contactForm: null,
      css_override: null,
      scripts_override: null
    }
  }
};
const websiteCMSSlice = createSlice({
  name: "websiteCms",
  initialState,
  reducers: {
    updateWebsiteCMS: (state, action) => {
      return __spreadValues(__spreadValues({}, state), action.payload);
    },
    resetWebsiteCMS: (state) => {
      return __spreadValues(__spreadValues({}, state), initialState);
    },
    updateWebsiteCMSPageBuilder: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        pageBuilder: __spreadValues(__spreadValues({}, state.pageBuilder), action.payload)
      });
    },
    resetWebsiteCMSPageBuilder: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        pageBuilder: initialState.pageBuilder
      });
    },
    updateWebsiteCMSPageSlices: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        pageBuilder: __spreadProps(__spreadValues({}, state.pageBuilder), {
          pageSlices: action.payload
        })
      });
    },
    resetWebsiteCMSPageSlices: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        pageBuilder: __spreadProps(__spreadValues({}, state.pageBuilder), {
          pageSlices: []
        })
      });
    },
    updateWebsiteCMSPageData: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        pageBuilder: __spreadProps(__spreadValues({}, state.pageBuilder), {
          data: __spreadValues(__spreadValues({}, state.pageBuilder.data), action.payload)
        })
      });
    },
    resetWebsiteCMSPageData: (state, action) => {
      return __spreadProps(__spreadValues({}, state), {
        pageBuilder: __spreadProps(__spreadValues({}, state.pageBuilder), {
          data: __spreadValues({}, initialState.pageBuilder.data)
        })
      });
    }
  }
});
export const {
  updateWebsiteCMS,
  resetWebsiteCMS,
  updateWebsiteCMSPageBuilder,
  resetWebsiteCMSPageBuilder,
  updateWebsiteCMSPageData,
  resetWebsiteCMSPageData,
  updateWebsiteCMSPageSlices,
  resetWebsiteCMSPageSlices
} = websiteCMSSlice.actions;
export default websiteCMSSlice.reducer;
export const selectWebsiteCMSState = createSelector((state) => state.websiteCms, (websiteCms) => websiteCms);
export const selectWebsiteCMSPageBuilderState = createSelector((state) => state.websiteCms, (websiteCms) => websiteCms.pageBuilder);
export const selectWebsiteCMSPageSlicesState = createSelector((state) => state.websiteCms, (websiteCms) => websiteCms.pageBuilder.pageSlices);
